import { useEffect, useMemo } from "react";
import {
    RentHousesObjectFullInfo,
    RentHousesObjectPatchStatus,
    RentHousesObjectStatus,
} from "../../../../types/rentHouses";
import { NavbarApartmentsNewName } from "../constants";
import { ApartmentsNewStep } from "./useApartmentsNew";
import moment from "moment/moment";
import { DOTTED_DATE_FORMAT } from "../../../../constants/dates-format";

export const useCommentModeration = (
    data: RentHousesObjectFullInfo | undefined,
    setStep: (step: ApartmentsNewStep) => void
) => {
    const isHasCommentModeration = useMemo(() => {
        return (
            data?.status === RentHousesObjectStatus.DECLINED ||
            (data?.status === RentHousesObjectStatus.PUBLISHED &&
                data.patches?.[0]?.status === RentHousesObjectPatchStatus.DECLINED) ||
            !!data?.blocked
        );
    }, [data]);

    const commentModeration = useMemo(() => {
        if (!isHasCommentModeration) return undefined;
        if (data?.blocked)
            return `Объект снят с публикации до ${moment(data.blockedUntil).format(
                DOTTED_DATE_FORMAT
            )}\nПричина блокировки: ${data.blockedReason}`;

        if (data?.status === RentHousesObjectStatus.DECLINED) return data?.declineReason;

        if (data?.status === RentHousesObjectStatus.PUBLISHED) {
            const hasDeclineReason = data.patches?.filter((patch) => !!patch.declineReason) || [];
            return hasDeclineReason.shift()?.declineReason;
        }
    }, [data, isHasCommentModeration]);

    useEffect(() => {
        if (isHasCommentModeration) setStep(NavbarApartmentsNewName.COMMENT);
    }, [isHasCommentModeration, setStep]);

    return { isHasCommentModeration, commentModeration };
};
