import moment from "moment";
import { getHoldingSpace } from "../../../../api/dictionaries";
import { getImageById } from "../../../../api/files";
import {
    DATE_2061,
    DAY_DATE_FORMAT,
    MID_DAY_MONTH_DATE_FORMAT,
    MIN_DAY_LONG_MONTH_DATE_FORMAT,
    MIN_DAY_MONTH_DATE_FORMAT,
} from "../../../../constants/dates-format";
import { languageRuId } from "../../../../constants/language";
import { TSelectValue } from "../../../../types/dictionaries";
import { Tariff } from "../../components/tariffs/types";
import {
    ExcursionDataClient,
    ExcursionDataGet,
    ExcursionDataPost,
    ExcursionEventItem,
    ExсursionDataPatch,
} from "./types";
import { getHoursAndMinutes, getStringFromHoursMinutes } from "../../utils";
import {
    getIsDaysExisting,
    getIsWeekDaysExisting,
    getRangeArray,
    getStringArray,
} from "../../../../components/timetable/place-timetable/utils";
import { weekDaysNames } from "../../../../components/timetable/place-timetable/constants";
import { DateControlValue, extendedDateFormat, isEqualObjects } from "@russpass/partner-front-ui";
import { PlaceTimetableItemType } from "../../../../components/timetable/place-timetable/types";
import { EventPreviewDataType } from "../../components/EventPreviewModal";

export const formatExcursionToPost = (
    data: ExcursionDataClient,
    tariffs: Tariff[],
    facility: string | null
): ExcursionDataPost => {
    const formattedData: any = {
        ...data,
        language: [languageRuId],
        isCanBuy: tariffs.length > 0,
        images: data.imagesFiles?.length ? data.imagesFiles.map((img) => img.id).slice(1) : [],
        imageExplorePreview: data.imagesFiles?.length ? [data.imagesFiles[0].id] : [],
        imageDetailedPageMain: data.imagesFiles?.length ? [data.imagesFiles[0].id] : [],
        route: data.route.map((routeItem) => ({
            title: routeItem.title,
            events: routeItem.events
                .filter((event) => event.imageFiles)
                .map((event) => ({
                    title: event.title,
                    images: event.imageFiles?.map((img) => img.id),
                })),
        })),
        seasonStart: data.seasonUnlimited
            ? moment(new Date()).format(extendedDateFormat.EXTENDED_DATE_TIME_FORMAT)
            : data.seasonStart,
        seasonEnd: data.seasonUnlimited
            ? moment(DATE_2061).format(extendedDateFormat.EXTENDED_DATE_TIME_FORMAT)
            : data.seasonEnd,
        facility,
        price: tariffs.find((i) => i.isBasic)?.itemPrices[0].price || "",
        durationHours: getHoursAndMinutes(data.duration)[0],
        durationMinutes: getHoursAndMinutes(data.duration)[1],
        patchMode: false,
    };
    facility === null && delete formattedData["facility"];
    delete formattedData["id"];
    delete formattedData["imagesFiles"];
    delete formattedData["duration"];

    return formattedData;
};

export const formatExcursionToPatch = (
    data: ExcursionDataClient,
    tariffs: Tariff[],
    excursionViewDefaultId: string
): Omit<ExсursionDataPatch, "facility" | "sendToBilling" | "sendToCms" | "patchMode"> => {
    const duration = data.duration.split(" ");
    const hours = duration[0];
    const minutes = duration[2];
    const ticketPrice = tariffs.find((i) => i.isBasic)?.itemPrices[0].price || "";

    return {
        ...data,
        id: data.id,
        durationHours: +hours,
        durationMinutes: +minutes || 0,
        city: data.city || "",
        price: +ticketPrice,
        images: data.imagesFiles?.length ? data.imagesFiles.map((img) => img.id).slice(1) : [],
        imageDetailedPageMain: data.imagesFiles?.length ? [data.imagesFiles[0].id] : [],
        imageExplorePreview: data.imagesFiles?.length ? [data.imagesFiles[0].id] : [],
        maxGroupCount: data.maxGroupCount || data.minGroupCount || "",
        personalizedTickets: data.personalizedTickets ?? false,
        excursionView: data.excursionView || excursionViewDefaultId,
        seasonStart: data.seasonUnlimited
            ? moment(new Date()).format(extendedDateFormat.EXTENDED_DATE_TIME_FORMAT)
            : data.seasonStart,
        seasonEnd: data.seasonUnlimited
            ? moment(DATE_2061).format(extendedDateFormat.EXTENDED_DATE_TIME_FORMAT)
            : data.seasonEnd,
        route: data.route.map((routeItem) => ({
            title: routeItem.title,
            events: routeItem.events
                .filter((event) => event.imageFiles)
                .map((event) => ({
                    title: event.title,
                    images: event.imageFiles?.map((img) => img.id),
                })),
        })),
    };
};

export const formatExcursionFromGet = (
    initialExcursion: ExcursionDataClient,
    data: ExcursionDataGet
): ExcursionDataClient => {
    const excursion = { ...initialExcursion, ...data };
    const events = excursion.route[0]?.events.map((event: ExcursionEventItem) => {
        if (!event.images) {
            return event;
        }
        event.imageFiles = event.images.map((image) => ({
            url: getImageById(image),
            id: image,
        }));
        return event;
    });
    const imagesFiles = [...excursion.imageExplorePreview, ...excursion.images].map((img) => {
        return {
            url: getImageById(img),
            title: "",
            id: img,
            fileName: "",
        };
    });
    return {
        ...excursion,
        imagesFiles,
        route: [
            {
                ...excursion.route[0],
                events,
            },
        ],
        language: [languageRuId],
        duration: getStringFromHoursMinutes(excursion.durationHours, excursion.durationMinutes),
        seasonUnlimited: excursion.seasonUnlimited ?? false,
    };
};

export const getHoldingSpaceOptions = async (): Promise<TSelectValue[]> => {
    let data: TSelectValue[] = [];
    try {
        const { rows } = await getHoldingSpace();
        data = rows;
    } catch (err) {
        console.error(err);
    }
    return data;
};

const getArrayValue = (values: string): string[] => {
    if (values === "") {
        return [""];
    }

    const arrValue = values.split("\n");

    const arrValueWithoutEmptyString: string[] = [];
    const lengthArrValues = arrValue.length - 1;

    for (let i = 0; i <= lengthArrValues; i++) {
        if (i === lengthArrValues && arrValue[i] === "") {
            break;
        }

        if (arrValue[i][0] === "-") {
            arrValueWithoutEmptyString.push(arrValue[i].substring(1).trim());
        } else {
            arrValueWithoutEmptyString.push(arrValue[i].trim());
        }
    }

    return arrValueWithoutEmptyString;
};

const getTariffRowsPreviewDatesAndTimes = (workingTime: PlaceTimetableItemType[]) => {
    const weekDays: string[] = [];
    const separateDays: string[] = [];
    const rangeDays: string[] = [];

    workingTime?.forEach((item) => {
        const workingDays = item.workingDays as DateControlValue;

        const isWeekDays = getIsWeekDaysExisting(workingDays);
        const isSeparateDays = getIsDaysExisting(workingDays);

        if (isWeekDays || isSeparateDays) {
            const days = getStringArray(workingDays);

            days.forEach((day) => {
                if (weekDaysNames.hasOwnProperty(day)) {
                    weekDays.push(weekDaysNames[day as keyof typeof weekDaysNames]);
                } else {
                    separateDays.push(moment(day).format(MIN_DAY_MONTH_DATE_FORMAT));
                }
            });
        } else {
            //range
            const days = getRangeArray(workingDays);

            days.forEach((range) => {
                const rangeFrom = range.from;
                const rangeTo = range.to;

                const isSameMonth = moment(rangeFrom).isSame(rangeTo, "month");

                const from = moment(rangeFrom).format(isSameMonth ? DAY_DATE_FORMAT : MID_DAY_MONTH_DATE_FORMAT);
                const to = moment(rangeTo).format(MID_DAY_MONTH_DATE_FORMAT);

                rangeDays.push(from + " - " + to);
            });
        }
    });

    return {
        weekDays,
        separateDays,
        rangeDays,
    };
};

const getTariffRowsPreview = (tariffs: Tariff[]) => {
    let weekDaysRow = "";
    let separateDaysRow = "";
    let rangeDaysRow = "";

    const weekDays: {
        dates: string[];
        times: string[];
    } = {
        dates: [],
        times: [],
    };

    const separateDays: {
        dates: string[];
        times: string[];
    } = {
        dates: [],
        times: [],
    };

    const rangeDays: {
        dates: string[];
        times: string[];
    } = {
        dates: [],
        times: [],
    };

    const filteredTariffs = tariffs.filter(
        (tariff, indx, self) =>
            indx ===
            self.findIndex(
                (t) => isEqualObjects(tariff.workingTime, t.workingTime) && tariff.openedSince === t.openedSince
            )
    );

    filteredTariffs.forEach((tariff) => {
        const rawDatesAndTimes = getTariffRowsPreviewDatesAndTimes(tariff.workingTime);
        const time = tariff.openedSince.slice(0, 5);

        if (rawDatesAndTimes.weekDays.length) {
            weekDays.dates = weekDays.dates.concat(rawDatesAndTimes.weekDays);
            weekDays.times = weekDays.times.concat(time);
        }

        if (rawDatesAndTimes.separateDays.length) {
            separateDays.dates = separateDays.dates.concat(rawDatesAndTimes.separateDays);
            separateDays.times = separateDays.times.concat(time);
        }
        if (rawDatesAndTimes.rangeDays.length) {
            rangeDays.dates = rangeDays.dates.concat(rawDatesAndTimes.rangeDays);
            rangeDays.times = rangeDays.times.concat(time);
        }
    });

    const getRowItem = (items: string[]) =>
        items
            .filter((item, indx, self) => self.indexOf(item) === indx)
            .reduce((prev, next, indx) => prev + (indx !== items.length && indx !== 0 ? ", " : "") + next, "");

    if (weekDays.dates.length) {
        weekDaysRow += getRowItem(weekDays.dates) + " - " + getRowItem(weekDays.times);
    }

    if (separateDays.dates.length) {
        separateDaysRow += getRowItem(separateDays.dates) + " - " + getRowItem(separateDays.times);
    }

    if (rangeDays.dates.length) {
        rangeDaysRow += getRowItem(rangeDays.dates) + " - " + getRowItem(rangeDays.times);
    }

    return [weekDaysRow, separateDaysRow, rangeDaysRow];
};

export const getExcursionEventPreviewData = (data: ExcursionDataClient, tariffs: Tariff[]): EventPreviewDataType => ({
    title: data.title || "Название экскурсии",
    eventType: "Экскурсия",
    workingDate: data.seasonUnlimited
        ? "Круглогодично"
        : data.seasonStart && data.seasonEnd
        ? `${moment(data.seasonStart).format(MIN_DAY_LONG_MONTH_DATE_FORMAT)} 
            - ${moment(data.seasonEnd).format(MIN_DAY_LONG_MONTH_DATE_FORMAT)}`
        : "",
    images: data.imagesFiles || [],
    description: data.description,
    workingTime: {
        emptyValue: "ч",
        value: data.duration || "",
    },
    checkList: {
        program: getArrayValue(data.program),
        schedule: getTariffRowsPreview(tariffs),
        startPointAddress: data?.startPointAddress,
        startPointAdditInfo: data?.startPointAdditInfo,
        included: getArrayValue(data.included || ""),
        paidSeparately: getArrayValue(data.paidSeparately || ""),
    },
});
