import FormWrapper from "../../components/FormWrapper";
import { Form, useFormikContext } from "formik";
import { InputField } from "@russpass/partner-front-ui";
import { PickerOfArrivalField } from "./fields/picker-of-arrival-field";
import React from "react";
import { CheckinTypeDirectionField } from "./fields/checkin-type-direction-field";
import { HomeRuleDirectionField } from "./fields/home-rule-direction-field";
import { ApartmentsNewRooms } from "./fields/apartments-new-rooms";
import { maskedInputNumbers } from "../../../../../utils/validation";
import { NavbarApartmentsNewName } from "../../constants";
import SubmitButton from "../../components/SubmitButton";
import { BaseStepProps } from "../../types";
import { ArchiveButton } from "../../components/ArchiveButton";

const withNamespace = (name: string) => `${NavbarApartmentsNewName.DETAILS}.${name}`;

export const DetailsStep = ({
    isNew,
    isArchive,
    isBlockedFields,
    isValid,
    isDirty,
    submit,
    onEditArchive,
    isEditArchive,
}: BaseStepProps) => {
    const { isValid: isValidStep } = useFormikContext();

    return (
        <FormWrapper>
            <Form>
                <InputField
                    noHover
                    name={withNamespace("maxGuestCount")}
                    label="Максимальное количество гостей (взрослых и детей)"
                    placeholder="5"
                    hint="Для каждого гостя должно быть спальное место"
                    renderComponent={maskedInputNumbers}
                    disabled={isBlockedFields}
                />
                <InputField
                    noHover
                    name={withNamespace("bathroomCount")}
                    label="Ванная комната"
                    placeholder="1"
                    renderComponent={maskedInputNumbers}
                    disabled={isBlockedFields}
                />
                <PickerOfArrivalField
                    name={withNamespace("arrival")}
                    startProps={{ label: "Время заезда", placeholder: "14:00" }}
                    endProps={{ label: "Время выезда", placeholder: "12:00" }}
                    disabled={isBlockedFields}
                />
                <CheckinTypeDirectionField
                    dictionary="renthouse_checkin_types"
                    name={withNamespace("checkinType")}
                    label="Способ заселения"
                    placeholder="Выбрать"
                    disabled={isBlockedFields}
                />
                <HomeRuleDirectionField
                    name={withNamespace("homeRules")}
                    label="Правила дома"
                    placeholder="Выбрать"
                    dictionary="renthouse_home_rules"
                    disabled={isBlockedFields}
                />
                <ApartmentsNewRooms name={withNamespace("rooms")} disabled={isBlockedFields} />
                {isNew && <SubmitButton isValid={isValidStep}>Далее</SubmitButton>}
                {((!isNew && isDirty) || isEditArchive) && (
                    <SubmitButton isValid={isValid} fixed={!isEditArchive} onClick={submit}>
                        Отправить на модерацию
                    </SubmitButton>
                )}
                {isArchive && <ArchiveButton onEditArchive={onEditArchive} />}
            </Form>
        </FormWrapper>
    );
};
