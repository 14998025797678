import { partnerApiServices, russpassApiUrl, russpassApiServices } from "../constants/api";
import fetchRequest from "./manager";

const filesPartnerServiceUrl = `${russpassApiServices.partnership}${partnerApiServices.files}`;

export const getImageById = (id: string) => `${russpassApiUrl}${filesPartnerServiceUrl}/images/${id}`;

export const uploadPhotoToCms = async (file: File) => {
    const formData = new FormData();
    formData.append("file", file);
    const content: { id: string } = await fetchRequest.file(`${filesPartnerServiceUrl}/cms`, formData, {
        isProtected: true,
    });
    return content;
};

export const loadFileByUrlToCms = async (url: string) => {
    const data = await fetchRequest.post(`${filesPartnerServiceUrl}/cms/byUrl?url=${url}`, null, {
        isProtected: true,
    });
    return data;
};
