import React, { useMemo, useState, useCallback } from "react";
import history from "../../../history";
import { CommonLayout, StatusRequestEnum, UploadPhotosItem, useModalAlertContext } from "@russpass/partner-front-ui";
import {
    ExcursionHoldingSpace,
    navbarExcursionComment,
    NavbarExcursionNamesEnum,
    navbarExcursionPlace,
    navbarExcursionRatings,
} from "./helpers/constants";
import ExcursionInfo from "./components/excursion-info";
import useExcursion from "./hooks/useExcursion";
import ExcursionProgram from "./components/excursion-program";
import { useExursionActions } from "./hooks/useExursionActions";
import { useLocation } from "react-router-dom";
import routes from "../../../constants/routes";
import { navbarTypeEventNames } from "../all/constants";
import { Ratings } from "../components/ratings/ratings";
import ServiceComment from "../components/moderation-comment/service-comment";
import Tariffs from "../components/tariffs/tariffs";
import { ServiceTariffsContext } from "../components/tariffs/tariffs-context";
import useTariffs from "../components/tariffs/useTariffs";
import ActionsContainer from "../components/actions-container";
import Photos from "../components/photos";
import ServicePlace from "../components/service-place";
import useFacility from "../components/service-place/useFacility";
import { NotificationProps } from "../../../components/notification";
import eventDispatcher from "../../../lib/event-dispatcher";
import { EventPreviewModal } from "../components/EventPreviewModal";
import { OnlineSaleSwitch } from "../components/OnlineSaleSwitch";
import { getExcursionEventPreviewData } from "./helpers/utils";
import { StatusNamesServiceItem } from "../all/types";
import { alertConfirmSaveText, alertNoBasicTariffText, backButtonText } from "../service/helpers/modalTexts";
import useUserInfo from "../../../hooks/useUserInfo";
import { ReactComponent as IcExternalLink } from "../../../assets/images/icons/ic_external_link.svg";

const Excursion = () => {
    const { isGuide } = useUserInfo();

    const callNotification = (body: NotificationProps) => {
        eventDispatcher.setNotification({
            ...body,
        });
    };

    const [isOpenPreviewWindow, setIsOpenPreviewWindow] = useState(false);
    const [isEditMode, setEditMode] = useState(false);
    const { openModalAlert } = useModalAlertContext();

    const openExcursionPreviewWindow = useCallback(() => setIsOpenPreviewWindow(true), [setIsOpenPreviewWindow]);
    const closeExcursionPreviewWindow = useCallback(() => setIsOpenPreviewWindow(false), [setIsOpenPreviewWindow]);

    const { tariffs, loadTariffList, onChangeTariffList, isLoadingTariffList } = useTariffs();

    const {
        initialLoading,
        activeTabName,
        changeTab,
        excursionData,
        compareData,
        excursionForms,
        excursionViewOptions,
        onChangeData,
        reloadExcursionData,
        showcases,
        region,
        changeRegion,
        city,
        changeCity,
        isViewExcursion,
        holdingSpaceOptions,
        optionsNdsRate,
        hasChangesExceptBilling,
        hasBillingChanges,
    } = useExcursion({ loadTariffList });

    const {
        facility,
        city: facilityCity,
        region: facilityRegion,
        selectedPartnerFacility,
        optionsPlaceTypes,
        optionsFacilityPlaces,
        isExistingPartnerFacility,
        onChangeFacility,
        onChangeCity,
        onChangeRegion,
        changePartnerFacility,
        reloadFacilityData,
        hasChangesFacility,
        isLoadingFacility,
    } = useFacility({ callNotification, facilityId: excursionData?.facility });

    //TODO MOVE IN HOOK РАЗОБРАТЬСЯ
    const isShowServicePlace = useMemo(() => {
        const holdingSpace = holdingSpaceOptions.reduce(
            (space, currentSpace) =>
                !space && currentSpace.id === excursionData.holdingSpace && currentSpace.slug
                    ? currentSpace.slug
                    : space,
            ""
        );

        return holdingSpace === ExcursionHoldingSpace.mixed || holdingSpace === ExcursionHoldingSpace.indoor;
    }, [holdingSpaceOptions, excursionData.holdingSpace]);

    const reloadData = () => {
        reloadExcursionData();
        reloadFacilityData();
    };

    const {
        navbar,
        isSubmittingForm,
        sendExursionToModerate,
        sendExursionUpdate,
        isFormSending,
        isValidFacilityWorkingTime,
        addTariffForExistingExcursion,
        deleteTariffForExistingExcursion,
        updateTariffForExistingExcursion,
        updateExcursionFields,
    } = useExursionActions({
        data: excursionData,
        compareData,
        tariffs: tariffs.content,
        showcases,
        onChangeTariff: onChangeTariffList,
        facility,
        isExistingPartnerFacility,
        selectedPartnerFacility,
        isShowServicePlace,
        excursionViewDefaultId: excursionViewOptions[0]?.id,
        setEditMode,
        onChangeData,
        reloadData,
    });

    const isShowValiditySwitcher = useMemo(
        () => isValidFacilityWorkingTime && facility.workingTime.length > 0 && isShowServicePlace,
        [facility.workingTime.length, isShowServicePlace, isValidFacilityWorkingTime]
    );

    const location = useLocation<{ status: string }>();

    const localNavbar = useMemo(() => {
        let _navbar = navbar;

        if (isViewExcursion) {
            _navbar = _navbar.concat(navbarExcursionRatings);
        }

        if (excursionData.isRejected) {
            _navbar = navbarExcursionComment.concat(_navbar);
        }

        const placeIndex = navbar.reduce(
            (index, navbarItem, currentIndex) =>
                navbarItem.name === NavbarExcursionNamesEnum.Place ? currentIndex : index,
            -1
        );

        if (isShowServicePlace) {
            if (placeIndex === -1) {
                const tariffsIndex = navbar.reduce(
                    (index, navbarItem, currentIndex) =>
                        navbarItem.name === NavbarExcursionNamesEnum.Tariffs ? currentIndex : index,
                    0
                );
                _navbar.splice(tariffsIndex + 1, 0, navbarExcursionPlace);
            }
        } else if (placeIndex !== -1) {
            _navbar.splice(placeIndex, 1);
        }

        return _navbar;
    }, [navbar, isViewExcursion, excursionData.isRejected, isShowServicePlace]);

    const back = () => {
        history.push({
            pathname: routes.servicesAll,
            state: { ...location?.state, type: navbarTypeEventNames.excursions },
        });
    };

    const isCreateMode = excursionData.status === StatusNamesServiceItem.Creating;
    const isViewMode = excursionData.status !== StatusNamesServiceItem.Creating;

    const hasBasicTariff = tariffs.content.length > 0 && tariffs.content.find((i) => i.isBasic);

    const backButton = {
        title: "Назад",
        onClick: () => {
            if (hasBillingChanges && isEditMode) {
                openModalAlert(
                    backButtonText,
                    () => sendExursionUpdate(hasBillingChanges, hasChangesExceptBilling),
                    back
                );
                return;
            }
            back();
        },
    };

    const tariffsProviderValue = useMemo(
        () => ({
            tariffs,
            loadTariffList,
            billingProductId: excursionData.billingProductId,
            onChangeTariffList,
        }),
        [excursionData.billingProductId, loadTariffList, onChangeTariffList, tariffs]
    );

    const images = useMemo(() => excursionData.imagesFiles.map((item) => ({ image: item.id })) || [], [excursionData]);

    const onChangeImage = useCallback(
        (values: UploadPhotosItem[]) =>
            onChangeData({ ...excursionData, imagesFiles: values.map((item) => ({ id: item.image })) }),
        [excursionData, onChangeData]
    );

    const alertNoBasicTariff = () => {
        changeTab(NavbarExcursionNamesEnum.Tariffs);
        openModalAlert(alertNoBasicTariffText, () => {});
    };

    const alertConfirmSave = () => {
        openModalAlert(
            alertConfirmSaveText,
            () => sendExursionUpdate(hasBillingChanges, hasChangesExceptBilling),
            () => {}
        );
    };

    const currentTabIndex = localNavbar.findIndex((tab) => tab.name === activeTabName);
    const isLastTab = activeTabName === localNavbar[localNavbar.length - 1].name;
    const isTariffsTab = activeTabName === NavbarExcursionNamesEnum.Tariffs;

    const actionsObj = {
        setEdit: {
            text: "Редактировать",
            action: () => setEditMode(true),
        },
        sendModerate: {
            text: "Отправить на модерацию",
            action: () => {
                if (!hasBasicTariff) {
                    return alertNoBasicTariff();
                }
                !isFormSending && sendExursionToModerate();
            },
        },
        next: {
            text: "Далее",
            action: () => {
                if (activeTabName === NavbarExcursionNamesEnum.Tariffs && !hasBasicTariff) {
                    return alertNoBasicTariff();
                }
                changeTab(localNavbar[currentTabIndex + 1].name);
            },
        },
        save: {
            text: "Сохранить",
            action: () => (hasBillingChanges ? alertConfirmSave() : setEditMode(false)),
        },
        sendExursionUpdate: {
            text: "Отправить на модерацию",
            action: () => {
                if (!hasBasicTariff) {
                    return alertNoBasicTariff();
                }
                !isFormSending && sendExursionUpdate(hasBillingChanges, hasChangesExceptBilling);
            },
        },
    };

    const nextButton = () => {
        // при обычном просмотре
        if (isViewMode && !isEditMode) {
            return actionsObj["setEdit"];
        }
        //при создании
        if (!isViewMode && !isEditMode) {
            return isLastTab ? actionsObj["sendModerate"] : actionsObj["next"];
        }
        //при редактировании
        if (isEditMode) {
            // если изменили все остальные любые поля
            if (hasChangesExceptBilling || hasChangesFacility) {
                return actionsObj["sendExursionUpdate"];
            }
            // если изменили поля билинга
            if (!hasChangesExceptBilling) {
                return actionsObj["save"];
            }
            // по стандарту
            return actionsObj["save"];
        }

        return { action: () => {}, text: "" };
    };

    const handleToggleIsCanBuySwitch = async () => {
        if (isViewExcursion) {
            await updateExcursionFields({
                isCanBuy: !excursionData.isCanBuy,
            });
        } else {
            onChangeData({ ...excursionData, isCanBuy: !excursionData.isCanBuy });
            callNotification({
                status: StatusRequestEnum.Success,
                body: excursionData.isCanBuy
                    ? "Продажа на RUSSPASS отключена. Пользователи сайта смогут только ознакомиться с информацией"
                    : "Продажа на RUSSPASS включена",
            });
        }
    };

    const isShowSaleSwitch = tariffs.content.length > 0;

    const isVisibleActions = useMemo(() => {
        if (isViewExcursion && isTariffsTab) {
            return false;
        } else {
            return isEditMode ? true : !initialLoading && !isOpenPreviewWindow;
        }
    }, [initialLoading, isEditMode, isOpenPreviewWindow, isTariffsTab, isViewExcursion]);

    const disabledFields = isViewMode && !isEditMode;

    const isDisabledFacilityForm = useMemo(() => {
        if (isCreateMode) {
            return facility.cmsFacilityId && facility.cmsFacilityId === selectedPartnerFacility?.cmsFacilityId;
        }
        if (isViewMode) {
            if (isEditMode) {
                if (selectedPartnerFacility?.cmsFacilityId && isGuide) {
                    return !selectedPartnerFacility?.owner;
                }
                return false;
            }
            return true;
        }
    }, [isCreateMode, isViewMode, isEditMode, isGuide, facility, selectedPartnerFacility]);

    const topRightButton = useMemo(
        () =>
            excursionData.status === StatusNamesServiceItem.Active
                ? {
                      title: (
                          <>
                              Открыть на RUSSPASS <IcExternalLink className="button__icon button__icon--right" />
                          </>
                      ),
                      onClick: () => {
                          window.open(`${excursionData?.url}`);
                      },
                      iconMobile: <IcExternalLink className="button__icon button__icon--right" />,
                  }
                : undefined,
        [excursionData]
    );

    return (
        <ServiceTariffsContext.Provider value={tariffsProviderValue}>
            <CommonLayout
                pageTitle={excursionData.title || "Новая экскурсия"}
                backButton={backButton}
                activeTabName={activeTabName}
                handlerChangeActiveTab={changeTab}
                isLoadingWithoutContent={initialLoading}
                isLoadingWithContent={isFormSending || isLoadingTariffList || isLoadingFacility}
                navbar={localNavbar}
                fullHeight={activeTabName === NavbarExcursionNamesEnum.Ratings}
                isHideControlPanel
                topRightButton={topRightButton}
            >
                <div data-name={NavbarExcursionNamesEnum.Excursion}>
                    <ExcursionInfo
                        data={excursionData}
                        forms={excursionForms}
                        excursionViewOptions={excursionViewOptions}
                        onChange={onChangeData}
                        region={region}
                        onChangeRegion={changeRegion}
                        city={city}
                        onChangeCity={changeCity}
                        onSubmit={changeTab}
                        isShowErrors={isSubmittingForm}
                        disabled={disabledFields}
                        holdingSpaceOptions={holdingSpaceOptions}
                        optionsNdsRate={optionsNdsRate}
                        isCreateMode={isCreateMode}
                    />
                </div>
                <div data-name={NavbarExcursionNamesEnum.Photos}>
                    <Photos items={images} disabled={disabledFields} onChange={onChangeImage} />
                </div>
                <div data-name={NavbarExcursionNamesEnum.Place}>
                    <ServicePlace
                        facility={facility}
                        isDisabledChooserFacility={disabledFields}
                        isDisabledFacilityForm={isDisabledFacilityForm}
                        optionsPlaceTypes={optionsPlaceTypes}
                        onChangeFacility={onChangeFacility}
                        isShowErrors={isSubmittingForm}
                        region={facilityRegion}
                        onChangeRegion={onChangeRegion}
                        city={facilityCity}
                        onChangeCity={onChangeCity}
                        optionsFacilityPlaces={optionsFacilityPlaces}
                        selectedPartnerFacility={selectedPartnerFacility}
                        changePartnerFacility={changePartnerFacility}
                    />
                </div>
                <div data-name={NavbarExcursionNamesEnum.Program}>
                    <ExcursionProgram
                        data={excursionData}
                        onChange={onChangeData}
                        onSubmit={changeTab}
                        isShowErrors={isSubmittingForm}
                        disabled={disabledFields}
                    />
                </div>
                <div data-name={NavbarExcursionNamesEnum.Tariffs}>
                    {isShowSaleSwitch && (
                        <OnlineSaleSwitch onToggle={handleToggleIsCanBuySwitch} value={excursionData.isCanBuy} />
                    )}

                    <Tariffs
                        isExistingService={isViewExcursion}
                        addTariffForExistingService={addTariffForExistingExcursion}
                        deleteTariffForExistingService={deleteTariffForExistingExcursion}
                        updateTariffForExistingService={updateTariffForExistingExcursion}
                        isShowValiditySwitcher={isShowValiditySwitcher}
                        facilityWorkingTime={facility.workingTime}
                        updateDataField={(val) => updateExcursionFields({ price: val })}
                    />
                </div>
                <div data-name={NavbarExcursionNamesEnum.Ratings}>
                    <Ratings id={excursionData.cmsExcursionId} />
                </div>
                <div data-name={NavbarExcursionNamesEnum.Comment} data-hide={!excursionData.isRejected}>
                    <ServiceComment reasons={[excursionData.rejectionReason]} />
                </div>
            </CommonLayout>

            <>
                {isVisibleActions && (
                    <ActionsContainer
                        onClickNextButton={nextButton().action}
                        nextButtonText={nextButton().text}
                        onClickPreviewButton={openExcursionPreviewWindow}
                        showPreviwButton={isCreateMode || isEditMode}
                    />
                )}
            </>
            <EventPreviewModal
                isOpened={isOpenPreviewWindow}
                onClose={closeExcursionPreviewWindow}
                data={getExcursionEventPreviewData(excursionData, tariffs.content)}
            />
        </ServiceTariffsContext.Provider>
    );
};

export default Excursion;
