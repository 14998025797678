import moment from "moment";
import {
    DOTTED_SHORT_DATE_FORMAT,
    EXTENDED_DATE_TIME_FORMAT,
    MID_DATE_FORMAT,
    MIN_DAY_MONTH_DATE_FORMAT,
    YEAR_DATE_FORMAT,
} from "../../../constants/dates-format";
import { RentHousesBooking, RentHousesGuestsCount } from "../../../types/rentHouses";
import { declensionOfNum } from "../../../utils/declension-number";
import { combineString } from "../../../utils/text-util";

export const formatDateRange = (start: string, end: string, type: "default" | "extended" = "default") => {
    const fromMoment = moment(start, EXTENDED_DATE_TIME_FORMAT);
    const toMoment = moment(end, EXTENDED_DATE_TIME_FORMAT);
    if (type === "default") {
        return `${fromMoment.format(DOTTED_SHORT_DATE_FORMAT)} – ${toMoment.format(DOTTED_SHORT_DATE_FORMAT)}`;
    }
    if (fromMoment.year() !== toMoment.year()) {
        return [fromMoment.format(MID_DATE_FORMAT), toMoment.format(MID_DATE_FORMAT)].join(" – ");
    }

    return [
        [fromMoment.format(MIN_DAY_MONTH_DATE_FORMAT), toMoment.format(MIN_DAY_MONTH_DATE_FORMAT)].join(" – "),
        fromMoment.format(YEAR_DATE_FORMAT),
    ].join(" ");
};

export const formatPrice = (price?: number) => (price != null ? `${price.toLocaleString()} ₽` : "");

export const formatGuestsCount = (guests: RentHousesGuestsCount) => {
    const adults = guests.adults
        ? `${guests.adults} ${declensionOfNum(guests.adults, ["взрослый", "взрослых", "взрослых"])}`
        : undefined;
    const children = guests.children
        ? `${guests.children} ${declensionOfNum(guests.children, ["ребенок", "ребенка", "детей"])}`
        : undefined;

    return combineString([adults, children], ", ");
};

export const parseFullName = (user: { firstName: string; lastName: string; middleName?: string }) =>
    combineString([user.lastName, user.firstName, user.middleName]);

export const parseModalTitle = (booking: RentHousesBooking) =>
    `${formatDateRange(booking.checkinDate, booking.checkoutDate)} ${booking.object.title}`;

export const formatAges = (ages: number[]) =>
    ages.map((age) => `${age} ${declensionOfNum(age, ["год", "года", "лет"])}`).join(", ");
