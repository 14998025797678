import { useMemo } from "react";
import { CONTACT_FORM_TOPIC_v3 } from "../../../../../../constants/support";
import { contactFormTopicType_v3 } from "../../dictionary";
import getApartmentTopicOptions from "../../apartment/utils/getApartmentTopicOptions";
import useUserInfo from "../../../../../../hooks/useUserInfo";

const useTopicOptions = () => {
    const { isApartment } = useUserInfo();

    return useMemo(() => {
        if (isApartment) return getApartmentTopicOptions();

        return Object.values(CONTACT_FORM_TOPIC_v3).map((item) => ({
            value: item,
            label: contactFormTopicType_v3(item),
        }));
    }, [isApartment]);
};

export default useTopicOptions;
