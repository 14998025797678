import styles from "./EnterOtpForm.module.sass";
import classNames from "classnames";
import OtpInput from "./OtpInput";
import { Loader, StatusRequestEnum, formatPhone } from "@russpass/partner-front-ui";
import React, { useCallback, useEffect, useState } from "react";
import { useCountDown } from "../../../../../hooks/useCountDown";
import useLoadUserInfo from "../../../../../hooks/useLoadUserInfo";
import { generateOtp } from "../../../../../api/sso";
import eventDispatcher from "../../../../../lib/event-dispatcher";
import { OTP_ERROR, UNEXPECTED_ERROR } from "../../../../../constants/errors";
import { phoneVerify } from "../../../../../api/phoneVerification";

type EnterOtpFormProps = {
    phone: string;
    email: string;
    isOpen: boolean;
    setConfirmed: () => void;
};

const DEFAULT_WAIT_TIME_SEC = 60;

const EnterOtpForm = ({ phone, email, isOpen, setConfirmed }: EnterOtpFormProps) => {
    const [otp, setOtp] = useState("");

    const { counter: leftSec, start: startCountDown, isActive: isWaiting } = useCountDown();

    const requestCode = useCallback(async () => {
        setOtp("");

        try {
            await generateOtp({ mobile: phone.replace("+", "") });
            startCountDown(DEFAULT_WAIT_TIME_SEC);
        } catch (e) {
            if (e.data?.resp?.errorCode === 1062) {
                startCountDown(DEFAULT_WAIT_TIME_SEC - (e.data?.resp?.repeatAfter || 0));
                eventDispatcher.setNotification({
                    status: StatusRequestEnum.Error,
                    body: e?.data?.resp?.errorCause || UNEXPECTED_ERROR,
                });
                return;
            }

            eventDispatcher.setNotification({
                status: StatusRequestEnum.Error,
                body: UNEXPECTED_ERROR,
            });
        }
    }, [phone, startCountDown]);

    useEffect(() => {
        setOtp("");
        if (!isOpen || isWaiting) return;

        requestCode().then(null);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpen]);

    const { loadUserInfo } = useLoadUserInfo();
    const [isSendingCode, setIsSendingCode] = useState(false);

    const sendCode = useCallback(async () => {
        try {
            setIsSendingCode(true);
            await phoneVerify(otp);
            await loadUserInfo(email);
            setConfirmed();
        } catch (e) {
            setOtp("");

            if (e.data?.resp?.errorCode === 1039 && e.data?.resp?.status === 400) {
                eventDispatcher.setNotification({
                    status: StatusRequestEnum.Error,
                    body: OTP_ERROR,
                });
                return;
            }

            eventDispatcher.setNotification({
                status: StatusRequestEnum.Error,
                body: e?.data?.resp?.errorCause || UNEXPECTED_ERROR,
            });
        } finally {
            setIsSendingCode(false);
        }
    }, [otp, loadUserInfo, email, setConfirmed]);

    return (
        <div className={styles.Form}>
            <div className={styles.Header}>Введите код</div>
            <div className={classNames(styles.SubHeader, "mt-16")}>
                Сообщение с кодом подтверждения отправили на {formatPhone(phone)}{" "}
            </div>
            <div className={classNames(styles.InputOtpWrapper, "mt-32")}>
                <OtpInput value={otp} onChange={setOtp} onComplete={sendCode} />
                {isSendingCode && <Loader width="48px" />}
            </div>
            {isWaiting ? (
                <div className={styles.OtpWaiting}>Запросить новый код можно через {leftSec} сек.</div>
            ) : (
                <button
                    className={classNames("button button--accent", styles.RequestButton, {
                        "mt-32": !isSendingCode,
                        "mt-8": isSendingCode,
                    })}
                    onClick={requestCode}
                >
                    {isWaiting ? `Запросить новый код можно через ${leftSec} сек.` : "Запросить новый код"}
                </button>
            )}
        </div>
    );
};

export default EnterOtpForm;
