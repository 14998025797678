import { servicePlaceValidation } from "../../components/service-place/validation-schema";
import { Tariff } from "../../components/tariffs/types";
import { Facility } from "../../types";
import { ExcursionDataClient } from "./types";
import { excursionInfoValidation, excursionProgramValidation } from "./validation-schema";

type Props = {
    data: ExcursionDataClient;
    facility: Facility;
    tariffs: Tariff[];
    isShowServicePlace: boolean;
};

export const checkValidExursionFields = ({ data, facility, tariffs, isShowServicePlace }: Props) => {
    const isValidInfo = excursionInfoValidation.isValidSync(data);
    const isValidProgram = excursionProgramValidation.isValidSync(data);
    const isValidPhoto = !!data.imagesFiles && data.imagesFiles.length > 0;
    const isValidTariffs = !!tariffs && tariffs.length > 0;
    const isValidPlace = isShowServicePlace ? servicePlaceValidation.isValidSync(facility) : true;

    return {
        isValidInfo,
        isValidPlace,
        isValidPhoto,
        isValidTariffs,
        isValidProgram,
    };
};
