import Region from "../../../components/async-selects/region";
import SelectAddress from "../../../components/async-selects/address";
import SelectCity from "../../../components/async-selects/select-city";
import {
    AutoSubmit,
    InputField,
    SelectInputField,
    TextareaField,
    useViewportContext,
    ActionButtonWrapper,
    DndUploadPhotosField,
    QuestionTooltip,
} from "@russpass/partner-front-ui";
import { maskedInputCustomNumber, maskedInputPhone } from "../../../utils/validation";
import { RestaurantInfoFormType, RestaurantOptionsType } from "./types";
import { Form, Formik, FormikProps } from "formik";
import { restaurantInfoValidation } from "./validation-schema";
import { noop } from "lodash";
import classNames from "classnames";
import styles from "./restaurant-info.module.sass";
import PlaceTimetable from "../../../components/timetable/place-timetable/place-timetable";
import Chooser from "./components/chooser";
import { isCanReserveEnabled, isEnableRestaurantNewFields } from "../../../constants/feature-toggles";
import ReserveSwitch from "./components/reserve-switch";
import { getImageById, uploadPhotoToCms } from "../../../api/files";
import { onError, onFileTooLarge, onPhotosLimit } from "../../../utils/images";

type RestaurantInfoProps = {
    data: RestaurantInfoFormType;
    disabled: boolean;
    isChanged: boolean;
    isShowErrors: boolean;
    dictionaryOptions: RestaurantOptionsType;
    onChange: (values: RestaurantInfoFormType) => void;
    onSaveRestaurant: () => Promise<void>;
    setEnableEditRestaurant: () => void;
};

const RestaurantInfo = ({
    data,
    disabled,
    isChanged,
    isShowErrors,
    onChange,
    onSaveRestaurant,
    dictionaryOptions,
    setEnableEditRestaurant,
}: RestaurantInfoProps) => {
    const { isSmallTablet } = useViewportContext();
    return (
        <Formik
            initialValues={data}
            validationSchema={restaurantInfoValidation}
            enableReinitialize
            validateOnMount
            onSubmit={noop}
        >
            {({ values, setFieldValue }: FormikProps<RestaurantInfoFormType>) => {
                return (
                    <Form className="content-wrapper__form">
                        <AutoSubmit onChange={onChange} />
                        <div className="content-wrapper">
                            {isCanReserveEnabled && <ReserveSwitch disabled={disabled} />}
                            <TextareaField
                                name="name"
                                disabled={disabled}
                                noHover
                                label={"Название объекта"}
                                placeholder={"Новый объект"}
                                hint={"Отобразится на сайте RUSSPASS"}
                                errorSettings={{ showOnChange: isShowErrors }}
                                maxLength={100}
                            />
                            <TextareaField
                                name="description"
                                label={"Описание"}
                                hint={"Для детальной страницы объекта на сайте RUSSPASS"}
                                noHover
                                maxRows={3}
                                placeholder={"Расскажите подробно о вашем объекте"}
                                maxLength={400}
                                errorSettings={{ showOnChange: isShowErrors }}
                                disabled={disabled}
                            />
                            <InputField
                                name="bill"
                                label={"Средний чек"}
                                noHover
                                placeholder={"2 000 ₽"}
                                disabled={disabled}
                                renderComponent={maskedInputCustomNumber({
                                    suffix: " ₽",
                                    integerLimit: 5,
                                })}
                            />
                            <Chooser
                                name="cuisines"
                                selectFieldName="cuisine"
                                label="Кухня"
                                modalLabel="Кухня"
                                data={values.cuisines}
                                options={dictionaryOptions.cuisinesOptions}
                                handleChange={setFieldValue}
                                disabled={disabled}
                                errorSettings={{ showOnChange: isShowErrors }}
                            />
                            {isEnableRestaurantNewFields && (
                                <>
                                    <SelectInputField
                                        name="restaurantTypes"
                                        label="Тип заведения"
                                        placeholder="Выбрать"
                                        options={dictionaryOptions.restaurantTypeOptions}
                                        disabled={disabled}
                                        value={values.restaurantTypes}
                                        isChecked={true}
                                        handleChange={(value) => {
                                            setFieldValue("restaurantTypes", value);
                                        }}
                                        valueField="id"
                                        labelField="title"
                                    />
                                    <Chooser
                                        name="restaurantCharacteristic"
                                        selectFieldName="characteristic"
                                        label="Особенности"
                                        modalLabel="Особенности"
                                        data={values.restaurantCharacteristic || []}
                                        options={dictionaryOptions.restaurantCharacteristicOptions}
                                        handleChange={setFieldValue}
                                        disabled={disabled}
                                        errorSettings={{ showOnChange: isShowErrors }}
                                    />
                                    <Chooser
                                        name="restaurantTags"
                                        selectFieldName="tags"
                                        label={
                                            <div className={styles.restaurant_tags__label}>
                                                Категории{" "}
                                                <QuestionTooltip
                                                    body="Выберите категории, к которым можно отнести ваше заведение. Так пользователи смогут найти его быстрее, используя фильтры в каталоге"
                                                    settings={{ offsetX: 245, offsetY: 5, width: "280px" }}
                                                />
                                            </div>
                                        }
                                        modalLabel="Категории"
                                        data={values.restaurantTags || []}
                                        options={dictionaryOptions.restaurantTagsOptions}
                                        handleChange={setFieldValue}
                                        disabled={disabled}
                                        errorSettings={{ showOnChange: isShowErrors }}
                                    />
                                </>
                            )}
                            <InputField
                                name="phone"
                                label={"Телефон"}
                                noHover
                                placeholder={"+ 7 (999) 999-99-99"}
                                disabled={disabled}
                                errorSettings={{ showOnChange: isShowErrors }}
                                renderComponent={maskedInputPhone}
                            />
                            <InputField
                                name="email"
                                label="E-mail"
                                noHover
                                placeholder="name@mail.ru"
                                hint="На эту почту будут приходить заявки на бронирование"
                                disabled={disabled}
                                errorSettings={{
                                    showOnChange: isShowErrors,
                                }}
                            />
                            <Region
                                name="region.id"
                                value={values.region}
                                disabled={disabled}
                                placeholder={"Иркутская область"}
                                handleChange={(value) => setFieldValue("region", value)}
                                isShowErrors={isShowErrors}
                            />
                            <SelectCity
                                name="city.id"
                                value={values.city}
                                disabled={disabled}
                                placeholder={"Иркутск"}
                                handleChange={(value) => setFieldValue("city", value)}
                                isShowErrors={isShowErrors}
                            />
                            <SelectAddress
                                name="address.unrestricted_value"
                                value={values.address}
                                placeholder={"Улица, дом"}
                                disabled={disabled}
                                labelField="value"
                                handleChange={(value) => {
                                    setFieldValue("address", value);
                                    setFieldValue("coordinates", [
                                        Number(value.data?.geo_lat),
                                        Number(value.data?.geo_lon),
                                    ]);
                                }}
                                errorSettings={{ showOnChange: isShowErrors }}
                            />
                            <PlaceTimetable
                                data={values.workingTime}
                                handleChange={(timetableValues) => {
                                    setFieldValue("workingTime", timetableValues);
                                }}
                                disabled={disabled}
                                errorSettings={{ showOnChange: isShowErrors }}
                                isWorkingMode
                                label={"Режим работы"}
                                btnAddItemTitle={"Добавить рабочие дни"}
                                modalTitle={"Режим работы"}
                            />
                        </div>
                        <DndUploadPhotosField
                            name="images"
                            items={values.images.map((image) => ({ image })) || []}
                            disabled={disabled}
                            onChange={(value) =>
                                setFieldValue(
                                    "images",
                                    value.map((item) => item.image)
                                )
                            }
                            errorSettings={{ showOnChange: isShowErrors }}
                            desc={"От 2 до 15 фото по 15 мб, формат png, jpeg"}
                            getImageById={getImageById}
                            uploadPhoto={uploadPhotoToCms}
                            onFileTooLarge={onFileTooLarge}
                            onPhotosLimit={onPhotosLimit}
                            onError={onError}
                            isAvailablePhotoGuide={false}
                        />
                        {(disabled || isChanged) && (
                            <div
                                className={classNames(
                                    "content-wrapper",
                                    styles.action_button_container,
                                    isSmallTablet ? "action-button-sticky" : ""
                                )}
                            >
                                <ActionButtonWrapper>
                                    {!disabled && isChanged && (
                                        <button
                                            onClick={onSaveRestaurant}
                                            className={classNames("button button--full-width", "button--primary")}
                                        >
                                            {"Отправить на модерацию"}
                                        </button>
                                    )}
                                    {disabled && (
                                        <button
                                            onClick={setEnableEditRestaurant}
                                            className={classNames("button button--full-width", "button--second")}
                                        >
                                            {"Редактировать для публикации"}
                                        </button>
                                    )}
                                </ActionButtonWrapper>
                            </div>
                        )}
                    </Form>
                );
            }}
        </Formik>
    );
};

export default RestaurantInfo;
