import React, { useCallback, useMemo } from "react";
import { ReactComponent as ExternalLink } from "../assets/images/icons/external-link.svg";
import { ReactComponent as User } from "../assets/images/icons/ic_user.svg";
import { ReactComponent as Question } from "../assets/images/icons/ic_question.svg";
import { ReactComponent as Page } from "../assets/images/icons/ic_page.svg";
import { ReactComponent as FileOut } from "../assets/images/icons/ic_file_out.svg";
import { ReactComponent as Orders } from "../assets/images/icons/ic_ application.svg";
import { ReactComponent as FeedbackIcon } from "../assets/images/icons/ic_help_chat.svg";
import { ReactComponent as MailIcon } from "../assets/images/icons/ic_mail.svg";
import { ReactComponent as NotificationsIcon } from "../assets/images/icons/ic_notifications.svg";
import { KeyedMutator } from "swr";
import routes from "../constants/routes";
import { getFeedbackCounter } from "../api/feedback";
import { FeedbackStatus } from "../containers/feedback/constants";
import { getNotifications } from "../api/notficator";
import { isHotelNotificationsEnabled, isRentHousesSupportChatEnabled } from "../constants/feature-toggles";
import { getReservations } from "../api/restaurants/booking";
import { BOOKING_STATUSES } from "../containers/restaurants/booking/constants";
import { PaginatedListResponse, ServerResponse } from "../types/api";
import { RentHousesBookingItem, RentHousesBookingStatus } from "../types/rentHouses";
import { DialoguesListType } from "../containers/apartments/dialogues/types/DialoguesListResponse";
import { getOrdersAll } from "../api/orders";
import { orderStatuses } from "../containers/orders/constants";
import { MenuItemType } from "@russpass/partner-front-ui";
import useAccessManager from "./useAccessManager";
import useUserInfo from "./useUserInfo";
import { useRentHousesBookings, useRentHousesOwnerProfile } from "../api/hooks/rentHouses";
import { useGetDialoguesList } from "../api/hooks/dialogues";
import { RentHousesSupportChat, RentHousesSupportChatStatus } from "../types/rentHousesSupport";
import { useRentHousesSupportChat } from "../api/hooks/rentHousesSupport";

const useMenu = () => {
    const { getIsAvailableRoute } = useAccessManager();

    const { isSignedApartment, isMobileVerified, isHotel, isRestaurant, isApartment, isSignedGuide } = useUserInfo();

    const { data: ownerProfileData } = useRentHousesOwnerProfile(undefined, !isSignedApartment && !isSignedGuide);

    const getMenu = useCallback(
        (menu: MenuItemType[]) => {
            return menu.map((item) => ({
                ...item,
                disabled: !getIsAvailableRoute(item.routeName),
            }));
        },
        [getIsAvailableRoute]
    );

    const getFooterMenu = useCallback(
        (showProfileAlert?: boolean): MenuItemType[] => {
            return getMenu([
                {
                    title: "Профиль",
                    routeName: routes.profile,
                    icon: <User />,
                    alert: showProfileAlert
                        ? {
                              content: (
                                  <div style={{ textAlign: "start" }}>
                                      Добавьте информацию о себе, загрузите фотографию и подтвердите номер телефона.
                                  </div>
                              ),
                              width: 244,
                          }
                        : undefined,
                },
                {
                    title: "Поддержка",
                    routeName: routes.support,
                    icon: <Question />,
                },
            ]);
        },
        [getMenu]
    );

    const getMainMenuHotel = useCallback((): MenuItemType[] => {
        return getMenu([
            {
                title: "Объекты",
                routeName: routes.hotels,
                icon: <Page />,
            },
            {
                title: "Документы",
                routeName: routes.documents,
                icon: <FileOut />,
            },
            {
                title: "Вопросы туристов",
                routeName: routes.feedback,
                count: async (): Promise<number | undefined> => {
                    if (!getIsAvailableRoute(routes.feedback)) {
                        return undefined;
                    }
                    try {
                        return await getFeedbackCounter(FeedbackStatus.New);
                    } catch (err) {
                        console.error(err);
                    }
                },
                icon: <FeedbackIcon />,
            },
            {
                title: "Уведомления",
                routeName: routes.notifications,
                icon: <NotificationsIcon />,
                count: async (): Promise<number | undefined> => {
                    if (!getIsAvailableRoute(routes.notifications)) {
                        return undefined;
                    }
                    try {
                        return (await getNotifications({ fired: true, read: false, pageSize: 1 })).data.total;
                    } catch (e) {
                        console.error(e);
                    }
                },
                isView: isHotelNotificationsEnabled,
            },
            {
                title: "Аналитика",
                routeName: routes.analytics,
                icon: <ExternalLink />,
                isView: getIsAvailableRoute(routes.analytics),
            },
        ]);
    }, [getIsAvailableRoute, getMenu]);

    const getMainMenuRestaurant = useCallback((): MenuItemType[] => {
        return getMenu([
            {
                title: "Объекты",
                routeName: routes.restaurantAll,
                icon: <Page />,
            },
            {
                title: "Бронирования",
                routeName: routes.bookingAll,
                count: async (): Promise<number | undefined> => {
                    if (!getIsAvailableRoute(routes.bookingAll)) {
                        return undefined;
                    }
                    try {
                        const response = await getReservations({
                            status: BOOKING_STATUSES.new,
                        });
                        return response.totalElements;
                    } catch (err) {
                        console.error(err);
                    }
                },
                icon: <Orders />,
            },
            {
                title: "Документы",
                routeName: routes.documents,
                icon: <FileOut />,
            },
        ]);
    }, [getIsAvailableRoute, getMenu]);

    const getMainMenuApartment = useCallback(
        (count: {
            bookings: KeyedMutator<PaginatedListResponse<RentHousesBookingItem>>;
            dialogues: KeyedMutator<DialoguesListType | undefined>;
            support: KeyedMutator<ServerResponse<RentHousesSupportChat>>;
        }): MenuItemType[] => {
            return getMenu([
                {
                    title: "Объекты",
                    routeName: routes.apartmentsAll,
                    icon: <Page />,
                },
                {
                    title: "Бронирования",
                    routeName: routes.apartmentsBookingAll,
                    count: async (): Promise<number | undefined> => {
                        try {
                            const response = await count.bookings();
                            return response?.data?.total;
                        } catch (err) {
                            console.error(err);
                        }
                    },
                    icon: <Orders />,
                },
                {
                    title: "Документы",
                    routeName: routes.documents,
                    icon: <FileOut />,
                },
                {
                    title: "Сообщения",
                    routeName: routes.dialogues,
                    count: async (): Promise<number | undefined> => {
                        try {
                            const dialoguesResponse = await count.dialogues();
                            const total = dialoguesResponse?.totalUnread || 0;
                            if (!isRentHousesSupportChatEnabled) return total;

                            const supportChatResponse = await count.support();
                            if (supportChatResponse?.data?.userStatus !== RentHousesSupportChatStatus.NEW_MESSAGE)
                                return total;

                            return total + 1;
                        } catch (err) {
                            console.error(err);
                        }
                    },
                    icon: <MailIcon />,
                },
            ]);
        },
        [getMenu]
    );

    const getMainMenuDisplayObjects = useCallback((): MenuItemType[] => {
        return getMenu([
            {
                title: "Услуги",
                routeName: routes.services,
                icon: <Page />,
            },
            {
                title: "Заявки",
                routeName: routes.orders,
                count: async () => {
                    if (!getIsAvailableRoute(routes.orders)) {
                        return undefined;
                    }
                    try {
                        const { totalElements } = await getOrdersAll({ status: orderStatuses.hold });
                        return totalElements;
                    } catch (err) {
                        console.error(err);
                    }
                },
                icon: <Orders />,
            },
            {
                title: "Документы",
                routeName: routes.documents,
                icon: <FileOut />,
            },
            {
                title: "Вопросы туристов",
                routeName: routes.feedback,
                count: async (): Promise<number | undefined> => {
                    if (!getIsAvailableRoute(routes.feedback)) {
                        return undefined;
                    }
                    try {
                        return await getFeedbackCounter(FeedbackStatus.New);
                    } catch (err) {
                        console.error(err);
                    }
                },
                icon: <FeedbackIcon />,
            },
            {
                title: "Аналитика",
                routeName: routes.analytics,
                icon: <ExternalLink />,
                isView: getIsAvailableRoute(routes.analytics),
            },
        ]);
    }, [getIsAvailableRoute, getMenu]);

    const isAvailableRouteDialogues = useMemo(() => {
        return getIsAvailableRoute(routes.dialogues);
    }, [getIsAvailableRoute]);

    const isAvailableRouteApartmentsBookingAll = useMemo(() => {
        return getIsAvailableRoute(routes.apartmentsBookingAll);
    }, [getIsAvailableRoute]);

    const { mutate: updateHousesBookings } = useRentHousesBookings(
        { page: 1, pageSize: 1, status: RentHousesBookingStatus.PENDING },
        undefined,
        !isAvailableRouteApartmentsBookingAll
    );

    const { mutate: updateDialoguesData } = useGetDialoguesList(
        { page: 1, pageSize: 1 },
        undefined,
        !isAvailableRouteDialogues
    );

    const { mutate: updateSupportData } = useRentHousesSupportChat({
        isDisabledRequest: !isAvailableRouteDialogues,
    });

    const footerMenu = useMemo(() => {
        return getFooterMenu(
            (isSignedApartment && (!ownerProfileData?.data || !isMobileVerified)) ||
                (isSignedGuide && (!ownerProfileData?.data || !isMobileVerified))
        );
    }, [isSignedApartment, ownerProfileData?.data, isMobileVerified, getFooterMenu]);

    const mainMenu = useMemo(() => {
        if (isHotel) {
            return getMainMenuHotel();
        }
        if (isRestaurant) {
            return getMainMenuRestaurant();
        }
        if (isApartment) {
            return getMainMenuApartment({
                bookings: updateHousesBookings,
                dialogues: updateDialoguesData,
                support: updateSupportData,
            });
        }
        return getMainMenuDisplayObjects();
    }, [
        updateDialoguesData,
        updateHousesBookings,
        isHotel,
        isApartment,
        isRestaurant,
        getMainMenuDisplayObjects,
        getMainMenuApartment,
        getMainMenuRestaurant,
        getMainMenuHotel,
    ]);

    return {
        mainMenu,
        footerMenu,
    };
};

export default useMenu;
