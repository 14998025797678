import {
    RestaurantInfoType,
    RestaurantInfoFormType,
    AddressType,
    RestaurantSupportInfoType,
    RestaurantOptionsType,
} from "./types";
import store from "../../../store";
import { isCanReserveEnabled } from "../../../constants/feature-toggles";
import { TSelectValue } from "../../../types/dictionaries";
import { ColorActiveTab, HeaderTab } from "@russpass/partner-front-ui";

const email = store.getState().user.userFullInfo?.email;

export const navbarRestaurantNames = {
    object: "object",
    ratings: "ratings",
};

export const navbarRestaurantLabels = {
    object: "Объект",
    ratings: "Отзывы",
};

export const navbarRestaurant: HeaderTab[] = [
    {
        label: navbarRestaurantLabels.object,
        name: navbarRestaurantNames.object,
        colorActiveTab: ColorActiveTab.Secondary2,
    },
    {
        label: navbarRestaurantLabels.ratings,
        name: navbarRestaurantNames.ratings,
        colorActiveTab: ColorActiveTab.Secondary2,
    },
];

const initStateRestaurant: RestaurantInfoType = {
    name: "",
    description: "",
    city: "",
    region: "",
    bill: undefined,
    coordinates: [],
    address: "",
    workingTime: [],
    phone: "",
    email: email || "",
    images: [],
    imageDetailedPageMain: [],
    imageExplorePreview: [],
    avgTime: 60,
    cuisines: [],
    restaurantTypes: [],
    restaurantCharacteristic: [],
    restaurantTags: [],
};

const region: TSelectValue = {
    id: "",
    title: "",
};

const city: TSelectValue = {
    id: "",
    title: "",
};

const address: AddressType = {
    unrestricted_value: "",
    value: "",
    data: {},
};

const BASE_INIT_STATE_RESTAURANT_FORM = {
    ...initStateRestaurant,
    address,
    region,
    city,
};

export const initStateRestaurantForm: RestaurantInfoFormType = isCanReserveEnabled
    ? {
          ...BASE_INIT_STATE_RESTAURANT_FORM,
          canReserve: true,
      }
    : BASE_INIT_STATE_RESTAURANT_FORM;

export const initStateRestaurantSupportInfo: RestaurantSupportInfoType = {
    importDeniedReason: "",
    status: "draft",
    url: "",
};

export const initStateDictionaryOptions: RestaurantOptionsType = {
    cuisinesOptions: [],
    restaurantTypeOptions: [],
    restaurantCharacteristicOptions: [],
    restaurantTagsOptions: [],
};

export const modalAlertDetails = {
    title: "При выходе все заполненные данные пропадут",
    OKButton: "Выйти без сохранения",
    cancelButton: "Продолжить заполнение данных",
};
