import { Form, useFormikContext } from "formik";
import { InputField } from "@russpass/partner-front-ui";
import React from "react";
import FormWrapper from "../../components/FormWrapper";
import { TextareaField } from "@russpass/partner-front-ui";
import { maskedInputNumbers } from "../../../../../utils/validation";
import RentHousesDictionaryField from "../../../../../components/form-fields/renthouses-dictionary-field";
import SelectAmenitiesField from "./fields/SelectAmenitiesField";
import SelectObjectTypeField from "./fields/SelectObjectTypeField";
import { OBJECT_STEP_DESCRIPTION_MAX } from "./validationSchema";
import SelectAddressField from "./fields/SelectAddressField";
import { NavbarApartmentsNewName } from "../../constants";
import SubmitButton from "../../components/SubmitButton";
import { BaseStepProps } from "../../types";
import { useViewportContext } from "@russpass/partner-front-ui";
import { SelectTimezoneField } from "./fields/SelectTimezoneField";
import { ArchiveButton } from "../../components/ArchiveButton";

const withNamespace = (name: string) => `${NavbarApartmentsNewName.OBJECT}.${name}`;

export const ObjectStep = ({
    isNew,
    isArchive,
    isBlockedFields,
    isValid,
    isDirty,
    submit,
    onEditArchive,
    isEditArchive,
}: BaseStepProps) => {
    const { isValid: isValidStep } = useFormikContext();
    const { isMobile } = useViewportContext();

    return (
        <FormWrapper>
            <Form>
                <InputField
                    name={withNamespace("name")}
                    label="Название объекта"
                    noHover
                    placeholder="Новый объект"
                    hint="Эта информация отразится на странице вашего объекта на сайте RUSSPASS"
                    disabled={isBlockedFields}
                />
                <TextareaField
                    label="Описание"
                    name={withNamespace("description")}
                    noHover
                    placeholder="Расскажите подробнее о вашем объекте"
                    hint="Эта информация отразится на странице вашего объекта на сайте RUSSPASS"
                    maxLength={OBJECT_STEP_DESCRIPTION_MAX}
                    disabled={isBlockedFields}
                />
                <SelectObjectTypeField
                    name={withNamespace("type")}
                    label="Тип объекта"
                    placeholder="Выбрать"
                    disabled={isBlockedFields}
                />
                <InputField
                    name={withNamespace("area")}
                    label="Метраж"
                    noHover
                    placeholder="25м&#178;"
                    hint="Общая площадь объекта"
                    renderComponent={maskedInputNumbers}
                    disabled={isBlockedFields}
                />
                <SelectAmenitiesField
                    name={withNamespace("amenities")}
                    label="Удобства"
                    placeholder="Выбрать"
                    hint="Перечень удобств поможет туристам принять решение"
                    searchPlaceholder="Название удобства"
                    alertSettings={{
                        title: "Сохранить выбранные удобства?",
                        OKButton: "Сохранить и выйти",
                        cancelButton: "Выйти без сохранения",
                    }}
                    disabled={isBlockedFields}
                />
                <RentHousesDictionaryField
                    name={withNamespace("region")}
                    label="Регион"
                    selectPlaceholder="Иркутская область"
                    dictionary="regions"
                    searchPlaceholder={isMobile ? "Название региона" : undefined}
                    hideArrow
                    disabled={isBlockedFields}
                />
                <RentHousesDictionaryField
                    name={withNamespace("city")}
                    label="Населенный пункт"
                    selectPlaceholder="Иркутск"
                    dictionary="cities"
                    searchPlaceholder={isMobile ? "Название населенного пункта" : undefined}
                    hideArrow
                    disabled={isBlockedFields}
                />
                <SelectTimezoneField name={withNamespace("timeZone")} label="Часовой пояс" disabled={isBlockedFields} />
                <SelectAddressField
                    name={withNamespace("address")}
                    label="Адрес"
                    placeholder="Улица, дом"
                    disabled={isBlockedFields}
                />
                {isNew && <SubmitButton isValid={isValidStep}>Далее</SubmitButton>}
                {((!isNew && isDirty) || isEditArchive) && (
                    <SubmitButton isValid={isValid} fixed={!isEditArchive} onClick={submit}>
                        Отправить на модерацию
                    </SubmitButton>
                )}
                {isArchive && <ArchiveButton onEditArchive={onEditArchive} />}
            </Form>
        </FormWrapper>
    );
};
