import { useCallback, useMemo, useRef, useState } from "react";
import { NavbarApartmentsNewName } from "../constants";
import { getAddObjectSchema } from "../validationSchema";
import { AddApartmentRate, AddApartmentRoom, AddApartmentValues } from "../types";
import {
    createRentHousesObject,
    sendForModerationRentHousesObject,
    updateRentHousesObject,
} from "../../../../api/rentHouses";
import { mapObjectInfoToValues, mapValuesToObjectInfo, savePhotos } from "../helpers";
import eventDispatcher from "../../../../lib/event-dispatcher";
import { StatusRequestEnum } from "@russpass/partner-front-ui";
import getCustomErrorMessage from "../../../../utils/getCustomErrorMessage";
import { COMMON_RELOAD_ERROR } from "../../../../constants/errors";
import { useLocation, useParams } from "react-router-dom";
import { useRentHousesDictionary, useRentHousesObject } from "../../../../api/hooks/rentHouses";
import { RentHousesObjectStatus } from "../../../../types/rentHouses";
import history from "../../../../history";

export type ApartmentsNewStep = string | number | undefined;

enum SubmitStage {
    PHOTOS,
    SAVE,
    MODERATION,
}

const useApartmentsNew = () => {
    const { apartmentId } = useParams<{ apartmentId?: string }>();
    const { data } = useRentHousesObject(apartmentId, { revalidateOnFocus: false });
    const location = useLocation();

    const { data: timeZoneData } = useRentHousesDictionary(
        { dictionary: "time_zone", pageSize: 999, sort: "dictionary_data.title" },
        { revalidateOnFocus: false }
    );

    const isNew = useMemo(
        () => !apartmentId || data?.data?.status === RentHousesObjectStatus.DRAFT,
        [apartmentId, data]
    );

    const [step, setStep] = useState<ApartmentsNewStep>(
        new URLSearchParams(location.search).get("tab") || NavbarApartmentsNewName.OBJECT
    );

    const initialProps: AddApartmentValues = useMemo(() => {
        if (!data?.data || !timeZoneData?.data)
            return {
                [NavbarApartmentsNewName.DETAILS]: {
                    rooms: [{}] as AddApartmentRoom[],
                },
                [NavbarApartmentsNewName.PHOTO]: {
                    photos: [],
                },
                [NavbarApartmentsNewName.TARIFFS]: {
                    rates: [{}] as AddApartmentRate[],
                },
                [NavbarApartmentsNewName.SALE_AND_CANCEL]: {
                    cancellationPolicy: "",
                },
            };

        return mapObjectInfoToValues(data.data, { timeZone: timeZoneData?.data?.items });
    }, [data?.data, timeZoneData]);

    const submitValuesRef = useRef<AddApartmentValues | null>(null);
    const submitStageRef = useRef<SubmitStage>(SubmitStage.PHOTOS);
    const submitIdRef = useRef("");
    const submitObjectStatusRef = useRef<RentHousesObjectStatus | null>(null);

    const [submitStatus, setSubmitStatus] = useState<StatusRequestEnum | null>(null);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const handleStepChange = (newStep: NavbarApartmentsNewName) => {
        if (newStep !== step) {
            setStep(newStep);
            history.push({
                pathname: location.pathname,
                search: `tab=${newStep}`,
                state: location?.state,
            });
        }
    };

    const sendModeration = useCallback(
        async (values: AddApartmentValues) => {
            setIsSubmitting(true);
            if (values.rates?.rates) {
                values.rates.rates = values.rates.rates.filter((rate) => rate?.dates && rate?.price);
            }

            submitValuesRef.current = values;

            if (!submitIdRef.current && apartmentId) {
                submitIdRef.current = apartmentId;
            }

            if (submitStageRef.current === SubmitStage.PHOTOS) {
                const result = await savePhotos(submitValuesRef.current);
                if (result) {
                    submitValuesRef.current = { ...values, photo: { photos: result } };
                    submitStageRef.current = SubmitStage.SAVE;
                } else {
                    setSubmitStatus(StatusRequestEnum.Error);
                    setIsSubmitting(false);
                }
            }

            if (submitStageRef.current === SubmitStage.SAVE) {
                const objectInfo = mapValuesToObjectInfo(submitValuesRef.current);
                try {
                    if (!submitIdRef.current) {
                        const result = await createRentHousesObject(objectInfo);

                        submitIdRef.current = result?.data?.id || "";
                        submitObjectStatusRef.current = result?.data?.status || null;
                    } else {
                        const result = await updateRentHousesObject(submitIdRef.current, objectInfo);
                        submitObjectStatusRef.current = result?.data?.status || null;
                    }
                    submitStageRef.current = SubmitStage.MODERATION;
                } catch (e) {
                    eventDispatcher.setNotification({
                        status: StatusRequestEnum.Error,
                        body: getCustomErrorMessage(e, COMMON_RELOAD_ERROR),
                    });
                    setSubmitStatus(StatusRequestEnum.Error);
                }
            }

            if (submitStageRef.current === SubmitStage.MODERATION) {
                if (
                    submitObjectStatusRef.current &&
                    [
                        RentHousesObjectStatus.DRAFT,
                        RentHousesObjectStatus.DECLINED,
                        RentHousesObjectStatus.ARCHIVED,
                    ].includes(submitObjectStatusRef.current)
                ) {
                    try {
                        await sendForModerationRentHousesObject(submitIdRef.current);
                        setSubmitStatus(StatusRequestEnum.Success);
                    } catch (e) {
                        eventDispatcher.setNotification({
                            status: StatusRequestEnum.Error,
                            body: getCustomErrorMessage(e, COMMON_RELOAD_ERROR),
                        });
                        setSubmitStatus(StatusRequestEnum.Error);
                    }
                } else {
                    setSubmitStatus(StatusRequestEnum.Success);
                }
            }

            setIsSubmitting(false);
        },
        [apartmentId]
    );

    const repeat = useCallback(() => {
        if (!submitValuesRef.current) return;
        sendModeration(submitValuesRef.current);
    }, [sendModeration]);

    const newFormSubmit = {
        [NavbarApartmentsNewName.OBJECT]: () => setStep(NavbarApartmentsNewName.DETAILS),
        [NavbarApartmentsNewName.DETAILS]: () => setStep(NavbarApartmentsNewName.PHOTO),
        [NavbarApartmentsNewName.PHOTO]: () => setStep(NavbarApartmentsNewName.TARIFFS),
        [NavbarApartmentsNewName.TARIFFS]: () => setStep(NavbarApartmentsNewName.SALE_AND_CANCEL),
        [NavbarApartmentsNewName.SALE_AND_CANCEL]: sendModeration,
    }[step as string] as (values: any) => void;

    const schema = getAddObjectSchema(isNew, step as string);

    return {
        step,
        setStep: handleStepChange,
        initialProps,
        submit: isNew ? newFormSubmit : sendModeration,
        schema,
        submitStatus,
        repeat,
        isSubmitting,
    };
};

export default useApartmentsNew;
