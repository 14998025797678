import { defaultFormValidationSchema } from "../validationSchema";
import { DefaultFormData } from "../types/types";
import { FormikErrors, validateYupSchema, yupToFormErrors } from "formik";
import { useCallback, useEffect, useMemo, useState } from "react";
import { ContactFormTopic_v3 } from "../../../../../../types/support";
import { useViewportContext } from "@russpass/partner-front-ui";
import useTopicOptions from "./useTopicOptions";

type UseDefaultFormProps = {
    submitForm: (values: DefaultFormData) => Promise<void>;
    profile?: any;
};

const useDefaultForm = ({ submitForm, profile }: UseDefaultFormProps) => {
    const [initialErrors, setInitialErrors] = useState<FormikErrors<DefaultFormData>>({});

    const { isMobile } = useViewportContext();

    const initialValues = useMemo(() => {
        const values = {
            topic: "" as ContactFormTopic_v3,
            files: [],
            details: "",
            name: "",
            email: "",
            agreeTrueData: true,
        };
        if (profile) {
            values.name = profile.contactPerson;
            values.email = profile.email;
        }
        return values;
    }, [profile]);

    const onSubmit = useCallback(
        async (values: DefaultFormData) => {
            try {
                await submitForm(values);
            } catch (err) {
                console.error(err);
            }
        },
        [submitForm]
    );

    useEffect(() => {
        async function checkInitialValues() {
            try {
                await validateYupSchema(initialValues, defaultFormValidationSchema);
            } catch (err) {
                setInitialErrors(yupToFormErrors(err));
            }
        }
        checkInitialValues();
    }, [initialValues]);

    const topicOptions = useTopicOptions();

    return {
        topicOptions,
        initialErrors,
        initialValues,
        onSubmit,
        isMobile,
    };
};

export default useDefaultForm;
