import { ListItem, SortedServiceList } from "./types";
import {
    categoryLabel,
    creationDt,
    navbarServicesNames,
    navbarTypeEvent,
    navbarTypeTour,
    statusLabelsServices,
} from "./constants";
import {
    getIsArchivedService,
    getIsDraftService,
    getIsModerationService,
    getIsPublishedService,
    getNavNameStatus,
} from "../utils";
import routes from "../../../constants/routes";
import { getImageById } from "../../../api/files";
import { cloneDeep } from "lodash";
import { ObjectItemType, ObjectItemClassNameStatus, QuestionTooltip } from "@russpass/partner-front-ui";
import { isAvailableTours } from "../../../constants/feature-toggles";
import { sortingDescByField } from "../../../utils/sorting";

export const packingEventsByStatus = (dataFromServer: ListItem[]) => {
    const allServicesArray: ObjectItemType[] = [];
    const draftServicesArray: ObjectItemType[] = [];
    const moderationServicesArray: ObjectItemType[] = [];
    const publishedServicesArray: ObjectItemType[] = [];
    const refusedServicesArray: ObjectItemType[] = [];
    const archiveServicesArray: ObjectItemType[] = [];

    if (dataFromServer && dataFromServer.length) {
        dataFromServer.sort(sortingDescByField(creationDt)).forEach((serviceItem: ListItem) => {
            const { eventLink, imageEvent, idEvent, categoryDisplay } = getDataFromCmsId(serviceItem);
            const status = getNavNameStatus(serviceItem);

            const newItem: ObjectItemType = {
                id: idEvent,
                name: serviceItem.title,
                category: categoryDisplay,
                imgUrl: imageEvent,
                link: {
                    pathname: eventLink,
                    state: {
                        status,
                    },
                },
                creationDt: serviceItem.creationDt,
            };

            if (!serviceItem.isRejected) {
                if (getIsDraftService(serviceItem.status)) {
                    newItem.statusLabel = statusLabelsServices.draft;
                    newItem.classNameStatusLabel = ObjectItemClassNameStatus.draft;
                    draftServicesArray.push(newItem);
                }
                if (getIsModerationService(serviceItem.status)) {
                    newItem.statusLabel = (
                        <>
                            {statusLabelsServices.moderation}
                            <QuestionTooltip body="В течение 5 рабочих дней отправим ответ на почту" />
                        </>
                    );
                    newItem.classNameStatusLabel = ObjectItemClassNameStatus.moderation;
                    moderationServicesArray.push(newItem);
                }
                if (getIsPublishedService(serviceItem.status)) {
                    newItem.statusLabel = statusLabelsServices.published || undefined;
                    newItem.classNameStatusLabel = ObjectItemClassNameStatus.published;
                    publishedServicesArray.push(newItem);
                }
                if (getIsArchivedService(serviceItem.status)) {
                    newItem.statusLabel = statusLabelsServices.archive || undefined;
                    newItem.classNameStatusLabel = ObjectItemClassNameStatus.archive;
                    archiveServicesArray.push(newItem);
                }
            } else {
                newItem.statusLabel = statusLabelsServices.refused || undefined;
                newItem.classNameStatusLabel = ObjectItemClassNameStatus.refused;
                refusedServicesArray.push(newItem);
            }
            allServicesArray.push({
                ...newItem,
                link: {
                    pathname: eventLink,
                    state: { status: navbarServicesNames.all },
                },
            });
        });
    }

    return {
        draftServicesArray,
        moderationServicesArray,
        publishedServicesArray,
        refusedServicesArray,
        archiveServicesArray,
        allServicesArray,
    };
};

const getDataFromCmsId = (item: ListItem) => {
    switch (true) {
        case Boolean(item.cmsExcursionId):
            return {
                eventLink: `${routes.excursions}/view/${item.cmsExcursionId}`,
                imageEvent: item?.imageExplorePreview?.length ? getImageById(item.imageExplorePreview[0]) : undefined,
                categoryDisplay: categoryLabel.excursions || "",
                idEvent: item.cmsExcursionId || "",
            };
        case Boolean(item.cmsServiceId):
            return {
                eventLink: `${routes.events}/view/${item.cmsServiceId}`,
                imageEvent: item?.imageExplorePreview?.length ? getImageById(item.imageExplorePreview[0]) : undefined,
                categoryDisplay: categoryLabel.events || "",
                idEvent: item.cmsServiceId || "",
            };
        case Boolean(item.cmsTourId):
            return {
                eventLink: `${routes.tours}/view/${item.cmsTourId}`,
                imageEvent: item?.imageExplorePreview?.length ? getImageById(item.imageExplorePreview[0]) : undefined,
                categoryDisplay: categoryLabel.tours || "",
                idEvent: item.cmsTourId || "",
            };
        default:
            return {
                eventLink: "",
                imageEvent: undefined,
                categoryDisplay: "",
                idEvent: "",
            };
    }
};

export const getNavbarTypeServices = () => {
    const menu = cloneDeep(navbarTypeEvent);
    if (isAvailableTours) {
        menu.push(navbarTypeTour);
    }
    return menu;
};

export const concatServicesData = (...args: SortedServiceList[]) => {
    const concatData: SortedServiceList = {
        draftServicesArray: [],
        moderationServicesArray: [],
        publishedServicesArray: [],
        refusedServicesArray: [],
        archiveServicesArray: [],
        allServicesArray: [],
    };

    args.forEach((sortedServiceList) => {
        for (const key in sortedServiceList) {
            const serviceListKey = key as keyof SortedServiceList;
            concatData[serviceListKey] = concatData[serviceListKey].concat(sortedServiceList[serviceListKey]);
        }
    });

    for (const key in concatData) {
        const serviceListKey = key as keyof SortedServiceList;
        concatData[serviceListKey] = concatData[serviceListKey].sort(sortingDescByField(creationDt));
    }

    return concatData;
};
