import { Form, getIn, useFormikContext } from "formik";
import React, { useMemo, useRef } from "react";
import styles from "./styles.module.sass";
import { RatesField } from "./fields/RatesField/RatesField";
import { CalendarField } from "./fields/CalendarField/CalendarField";
import { NavbarApartmentsNewName } from "../../constants";
import SubmitButton from "../../components/SubmitButton";
import { BaseStepProps } from "../../types";
import { ArchiveButton } from "../../components/ArchiveButton";

const withNamespace = (name: string) => `${NavbarApartmentsNewName.TARIFFS}.${name}`;

export enum CalendarColors {
    BLUE = "blue",
    YELLOW = "yellow",
    PINK = "pink",
    GREEN = "green",
}

export const initialColorsList = {
    [CalendarColors.BLUE]: "",
    [CalendarColors.YELLOW]: "",
    [CalendarColors.PINK]: "",
    [CalendarColors.GREEN]: "",
};

export const TariffsStep = ({
    isNew,
    isArchive,
    isBlockedFields,
    isValid,
    isDirty,
    submit,
    onEditArchive,
    isEditArchive,
}: BaseStepProps) => {
    const { isValid: isValidStep, errors, submitCount } = useFormikContext();
    const colorsList = useRef(initialColorsList);

    const name = useMemo(() => withNamespace("rates"), []);
    const error = useMemo(() => getIn(errors, name), [errors, name]);

    return (
        <div className={styles.wrapper}>
            <Form>
                <div className={styles.container}>
                    <div className={styles.rates}>
                        <RatesField name={withNamespace("rates")} colorsList={colorsList} disabled={isBlockedFields} />
                        {!!submitCount && !!error && !Array.isArray(error) && (
                            <span className="input__hint input__hint--error">{error}</span>
                        )}
                    </div>
                    <div className={styles.calendar}>
                        <CalendarField name={withNamespace("rates")} colorsList={colorsList} />
                    </div>
                </div>
                {isNew && <SubmitButton isValid={isValidStep}>Далее</SubmitButton>}
                {((!isNew && isDirty) || isEditArchive) && (
                    <SubmitButton isValid={isValid} fixed={!isEditArchive} onClick={submit}>
                        Отправить на модерацию
                    </SubmitButton>
                )}
                {isArchive && <ArchiveButton onEditArchive={onEditArchive} />}
            </Form>
        </div>
    );
};
