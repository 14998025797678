import { HTMLProps, ReactNode } from "react";
import classNames from "classnames";
import styles from "./styles.module.sass";

type Props = Omit<HTMLProps<HTMLDivElement>, "children"> & {
    top: ReactNode;
    bottom: ReactNode;
};

const Ticket = ({ top, bottom, className, ...divProps }: Props) => (
    <div className={classNames(styles.Ticket, className)} {...divProps}>
        <div>{top}</div>
        <div className={styles.Divider} />
        <div>{bottom}</div>
    </div>
);

export default Ticket;
