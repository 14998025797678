import React, { FC } from "react";
import { ReactComponent as EyeIcon } from "../../../../assets/images/services/ic_eye.svg";
import { DndUploadPhotos, UploadPhotosItem } from "@russpass/partner-front-ui";
import { getImageById, uploadPhotoToCms } from "../../../../api/files";
import { onError, onFileTooLarge, onPhotosLimit } from "../../../../utils/images";

type PhotosType = {
    items: UploadPhotosItem[];
    disabled: boolean;
    onShowPreview?: () => void;
    onChange: (items: UploadPhotosItem[]) => void;
    onSubmit?: () => void;
};

const Photos: FC<PhotosType> = ({ items, disabled, onShowPreview, onChange, onSubmit }) => {
    return (
        <>
            <DndUploadPhotos
                items={items}
                onChange={onChange}
                disabled={disabled}
                title="Добавить фото на главную"
                getImageById={getImageById}
                uploadPhoto={uploadPhotoToCms}
                onFileTooLarge={onFileTooLarge}
                onPhotosLimit={onPhotosLimit}
                onError={onError}
            />
            {onShowPreview && (
                <div className="content-wrapper">
                    <div className="mt-32">
                        <button
                            type="submit"
                            className="button button--second button--full-width"
                            onClick={onSubmit}
                            data-qa-name="preview-open-button"
                        >
                            Предпросмотр
                            <span className="button__icon button__icon--left">
                                <EyeIcon />
                            </span>
                        </button>
                    </div>
                </div>
            )}
            {onSubmit && (
                <div className="content-wrapper">
                    <div className="mt-32">
                        <button
                            type="submit"
                            className="button button--second button--full-width"
                            onClick={onSubmit}
                            data-qa-name="send-to-moderate-button"
                        >
                            Отправить на модерацию
                        </button>
                    </div>
                </div>
            )}
        </>
    );
};

export default Photos;
