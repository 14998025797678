import { useCallback } from "react";
import { ListItem } from "./types";
import { getExcursionsAll } from "../../../api/excursion";
import { packingEventsByStatus } from "./helper";
import eventDispatcher from "../../../lib/event-dispatcher";
import { GET_DATA_ERROR } from "../../../constants/errors";
import { StatusRequestEnum } from "@russpass/partner-front-ui";

const useExcursions = () => {
    const loadExcursions = useCallback(async () => {
        try {
            const responseExcursions: ListItem[] = await getExcursionsAll();

            const {
                draftServicesArray,
                moderationServicesArray,
                publishedServicesArray,
                refusedServicesArray,
                archiveServicesArray,
                allServicesArray,
            } = packingEventsByStatus(responseExcursions);

            return {
                draftServicesArray,
                moderationServicesArray,
                publishedServicesArray,
                refusedServicesArray,
                archiveServicesArray,
                allServicesArray,
            };
        } catch (error) {
            eventDispatcher.setNotification({
                status: StatusRequestEnum.Error,
                body: GET_DATA_ERROR,
            });
            return {
                draftServicesArray: [],
                moderationServicesArray: [],
                publishedServicesArray: [],
                refusedServicesArray: [],
                archiveServicesArray: [],
                allServicesArray: [],
            };
        }
    }, []);

    return {
        loadExcursions,
    };
};

export default useExcursions;
