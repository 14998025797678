import { DataGridColumnType } from "@russpass/partner-front-ui";
import { orderStatuses, orderTypes, orderTypesNames } from "./constants";
import { ReactComponent as CircleIcon } from "../../assets/images/icons/circle.svg";
import moment from "moment";

const DATE_TIME_FORMAT = "DD MMM, HH:mm";

export const getOrderKindName = (kind: string) => {
    switch (kind) {
        case orderTypes.tour:
            return orderTypesNames.tour;
        case orderTypes.event:
            return orderTypesNames.event;
        case orderTypes.excursion:
            return orderTypesNames.excursion;
        default:
            return "";
    }
};

export const getEmptyDescription = (status: string) => {
    switch (status) {
        case orderStatuses.hold:
            return (
                <>
                    На подтверждение новых заявок дается 24 часа <br /> прежде чем они автоматически отклонятся
                </>
            );
        case orderStatuses.finished:
            return "Здесь будут заявки, которые вы взяли в работу";
        case orderStatuses.canceled:
            return (
                <>
                    Заявки, которые вы отклонили <br /> или не успели подтвердить за 24 часа
                </>
            );
        default:
            return "";
    }
};

export const getEmptyTitle = (status: string) => {
    switch (status) {
        case orderStatuses.hold:
            return "Новых заявок нет";
        case orderStatuses.finished:
            return "Подтвержденных заявок нет";
        case orderStatuses.canceled:
            return "Отклоненных заявок нет";
        default:
            return "";
    }
};

export const getDataGridOrderColumns = (isNew?: boolean): DataGridColumnType[] => {
    return [
        {
            value: (item) => item.id,
            label: "Номер заявки",
            width: "130px",
            dataQaNameColumn: "id",
        },
        {
            value: (item) => item.cmsTitle,
            label: (item) => getOrderKindName(item.kind),
            width: "204px",
            icon: isNew ? () => <CircleIcon width={12} height={12} fill="#FFCF08" /> : undefined,
            dataQaNameColumn: "object-name",
            dataQaStatus: (item) => {
                switch (item.status) {
                    case orderStatuses.hold:
                        return "new";
                    case orderStatuses.finished:
                        return "finished";
                    case orderStatuses.canceled:
                        return "canceled";
                    default:
                        return undefined;
                }
            },
        },
        {
            value: (item) => moment(item.createDateTime).format(DATE_TIME_FORMAT),
            label: "Дата заявки",
            width: "155px",
            dataQaNameColumn: "create-date-time",
        },
        {
            value: (item) => item.touristCount,
            label: "Туристы",
            width: "60px",
            dataQaNameColumn: "tourists",
        },
        {
            value: (item) => item.total + " ₽",
            label: "Сумма",
            width: "103px",
            dataQaNameColumn: "amount",
        },
        {
            value: (item) => moment(item.orderLines[0].activeSince).format(DATE_TIME_FORMAT),
            label: "Дата проведения",
            width: "147px",
            dataQaNameColumn: "date",
        },
    ];
};
