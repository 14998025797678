import { getFeatureToggleValue } from "@russpass/feature-toggle";

export const featureToggleNames = {
    IS_AVAILABLE_TOURS: "REACT_APP_IS_AVAILABLE_TOURS",
    IS_VISIBLE_ANALYTICS: "REACT_APP_VISIBLE_ANALYTICS",
    IS_ENABLE_RENTHOUSE_INSURANCE_INFORMATION: "REACT_APP_ENABLE_RENTHOUSE_INSURANCE_INFORMATION",
    IS_ENABLE_MOVING_OLD_TL: "REACT_APP_IS_ENABLE_MOVING_OLD_TL",
    RATING_V2: "REACT_APP_RATING_V2",
    IS_ENABLE_HOTEL_NOTIFICATIONS: "REACT_APP_HOTEL_NOTIFICATIONS_ENABLE",
    IS_ENABLE_RESTAURANT_NEW_FIELDS: "REACT_APP_IS_ENABLE_RESTAURANT_NEW_FIELDS",
    CAN_RESERVE_ENABLE: "REACT_APP_CAN_RESERVE_ENABLE",
    IS_RENTHOUSES_SUPPORT_CHAT_ENABLED: "REACT_APP_RENTHOUSES_SUPPORT_CHAT_ENABLED",
};

export const isAvailableTours = getFeatureToggleValue(featureToggleNames.IS_AVAILABLE_TOURS);
export const isVisibleAnalytics = getFeatureToggleValue(featureToggleNames.IS_VISIBLE_ANALYTICS);
export const isShowInsuranceInfo = getFeatureToggleValue(featureToggleNames.IS_ENABLE_RENTHOUSE_INSURANCE_INFORMATION);
export const isRatingV2 = getFeatureToggleValue(featureToggleNames.RATING_V2);
export const isHotelNotificationsEnabled = getFeatureToggleValue(featureToggleNames.IS_ENABLE_HOTEL_NOTIFICATIONS);
export const isEnableRestaurantNewFields = getFeatureToggleValue(featureToggleNames.IS_ENABLE_RESTAURANT_NEW_FIELDS);
export const isCanReserveEnabled = getFeatureToggleValue(featureToggleNames.CAN_RESERVE_ENABLE);
export const isRentHousesSupportChatEnabled = getFeatureToggleValue(
    featureToggleNames.IS_RENTHOUSES_SUPPORT_CHAT_ENABLED
);

// deprecated
export const isEnableMovingOldTL = getFeatureToggleValue(featureToggleNames.IS_ENABLE_MOVING_OLD_TL);
