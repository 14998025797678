import React, { useEffect, useRef } from "react";
import OrdersFilters from "../orders-filters";
import useOrder from "../../useOrder";
import useOrders from "../../useOrders";
import ModalOrder from "../modals/modal-order";
import { PAGINATE_SIZE } from "../../constants";
import { getDataGridOrderColumns, getEmptyDescription, getEmptyTitle } from "../../utils";
import { DataGrid } from "@russpass/partner-front-ui";
import { declensionOfNum } from "../../../../utils/declension-number";
import styles from "./styles.module.sass";

type Props = {
    status: string;
};

const OrderList = ({ status }: Props) => {
    const isFirstLoading = useRef<boolean>(true);
    const {
        data,
        isLoadingContent,
        isShowFiltersType,
        isEmpty,
        total,
        isNew,
        numberPage,
        loadContent,
        changeNumberPage,
    } = useOrders({ status });

    const { selectedOrder, selectOrder, isViewOrder, closeViewOrder, openModalConfirmAlert } = useOrder({
        loadContent,
    });

    useEffect(() => {
        if (isFirstLoading.current && !isLoadingContent) {
            isFirstLoading.current = false;
        }
    }, [isLoadingContent]);

    return (
        <>
            <DataGrid
                className={styles.container}
                isLoading={isLoadingContent}
                isEmpty={isEmpty}
                dataGridSetting={{
                    data: data.content,
                    columns: getDataGridOrderColumns(isNew),
                    total: `${total} ${declensionOfNum(total, ["заявка", "заявки", "заявок"])}`,
                    onSelect: (value) => selectOrder(value.id as number),
                }}
                emptySettings={{
                    title: getEmptyTitle(status),
                    description: getEmptyDescription(status),
                }}
                paginate={{
                    page: numberPage,
                    totalPages: Math.ceil(total / PAGINATE_SIZE),
                    onPageChange: changeNumberPage,
                }}
                filters={
                    (!isLoadingContent || !isFirstLoading.current) && (
                        <OrdersFilters isShowFiltersType={isShowFiltersType} />
                    )
                }
            />
            <ModalOrder
                isViewModal={isViewOrder}
                closeModal={closeViewOrder}
                selectedOrder={selectedOrder}
                onConfirm={() => openModalConfirmAlert(true)}
                onReject={() => openModalConfirmAlert(false)}
            />
        </>
    );
};

export default OrderList;
