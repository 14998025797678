/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useMemo, useState } from "react";
import { POST_DATA_ERROR, StatusRequestCardSubtitleError } from "../../../../constants/errors";
import { NavbarExcursionNamesEnum, navbarExcursion } from "../helpers/constants";
import { ExcursionDataClient, ExcursionDataCreated, ExcursionDataPost, ExсursionDataPatch } from "../helpers/types";
import { Tariff } from "../../components/tariffs/types";
import eventDispatcher from "../../../../lib/event-dispatcher";
import { createExcursion, sendExcursionForApproval, updateExcursion } from "../../../../api/excursion";
import { updateTariff } from "../../../../api/billing";
import { formatExcursionToPatch, formatExcursionToPost } from "../helpers/utils";
import { cloneDeep } from "lodash";
import { processingForUpdateTariff } from "../../utils";
import { createTariffs } from "../../components/tariffs/utils";
import { StatusRequestEnum } from "@russpass/partner-front-ui";
import useUserInfo from "../../../../hooks/useUserInfo";
import { Facility, PartnerFacilityType } from "../../types";
import { createFacility, sendFacilityForApproval, updateFacility } from "../../../../api/facility";
import { getTimetableData } from "../../service/utils";
import { checkValidExursionFields } from "../helpers/checkValidExursionFields";
import { NotificationProps } from "../../../../components/notification";
import { getErrorForm } from "../helpers/getErrorForm";
import history from "../../../../history";
import routes from "../../../../constants/routes";
import { getWorkingTimeValidation } from "../../components/service-place/validation-schema";
import { useCreateEventModalContext } from "../../components/contexts/createEventStatusModal";
import { CreateEventResultStatusEnum } from "../../components/CreateEventResultModal/create-event-modal-enum";

type Props = {
    data: ExcursionDataClient;
    compareData: ExcursionDataClient;
    tariffs: Tariff[];
    showcases: string[];
    onChangeTariff: (tariffs: Tariff[]) => void;
    facility: Facility;
    selectedPartnerFacility: PartnerFacilityType | null;
    isExistingPartnerFacility: boolean;
    isShowServicePlace: boolean;
    excursionViewDefaultId: string;
    setEditMode: React.Dispatch<React.SetStateAction<boolean>>;
    onChangeData: (values: ExcursionDataClient) => void;
    reloadData: () => void;
};

export const useExursionActions = ({
    data,
    compareData,
    tariffs,
    showcases,
    onChangeTariff,
    facility,
    selectedPartnerFacility,
    isExistingPartnerFacility,
    isShowServicePlace,
    excursionViewDefaultId,
    setEditMode,
    onChangeData,
    reloadData,
}: Props) => {
    const [navbar, setNavbar] = useState(cloneDeep(navbarExcursion));

    const [isFormSending, setIsFormSending] = useState(false);
    const [isSubmittingForm, setIsSubmittingForm] = useState(false);

    const [excursionSaved, setExcursionSaved] = useState<ExcursionDataCreated | null>(null);
    const [facilitySaved, setFacilitySaved] = useState<Pick<Facility, "id" | "cmsFacilityId"> | null>(null);
    const [tariffsAreSaved, setTariffsAreSaved] = useState<boolean>(false);
    const [facilitiesAreApproved, setFacilitiesAreApproved] = useState<boolean>(false);

    const { userFullInfo } = useUserInfo();

    const { openEventStatusModal } = useCreateEventModalContext();

    const callNotification = (body: NotificationProps) => {
        eventDispatcher.setNotification({
            ...body,
        });
    };

    const isValidFacilityWorkingTime = useMemo(
        () => getWorkingTimeValidation().isValidSync(facility.workingTime),
        [facility.workingTime]
    );

    const checkIsValid = () => {
        const { isValidInfo, isValidPlace, isValidPhoto, isValidTariffs, isValidProgram } = checkValidExursionFields({
            data,
            facility,
            tariffs,
            isShowServicePlace,
        });

        setNavbar((prev) => {
            return prev.map((item: any) => {
                if (item.name === NavbarExcursionNamesEnum.Excursion) item.isError = !isValidInfo;
                if (item.name === NavbarExcursionNamesEnum.Program) item.isError = !isValidProgram;
                if (item.name === NavbarExcursionNamesEnum.Photos) item.isError = !isValidPhoto;
                if (item.name === NavbarExcursionNamesEnum.Tariffs) item.isError = !isValidTariffs;
                if (item.name === NavbarExcursionNamesEnum.Place) item.isError = !isValidPlace;
                return item;
            });
        });

        if (!isValidInfo || !isValidPlace || !isValidPhoto || !isValidTariffs || !isValidProgram) {
            const error = getErrorForm({ isValidInfo, isValidPhoto, isValidProgram, isValidTariffs, isValidPlace });
            callNotification({
                status: StatusRequestEnum.Error,
                body: error || "",
            });
        }

        return isValidInfo && isValidPlace && isValidPhoto && isValidTariffs && isValidProgram;
    };

    useEffect(() => {
        setExcursionSaved(null);
        setTariffsAreSaved(false);
        setFacilitySaved(null);
    }, [data]);

    const sendExursionToModerate = useCallback(async () => {
        if ((!userFullInfo && !userFullInfo.email) || isFormSending) return;

        setIsSubmittingForm(true);
        if (!checkIsValid()) return;

        setIsFormSending(true);
        try {
            let facilityData, facilityCreated;

            if (isShowServicePlace) {
                facilityData = {
                    ...facility,
                    phones: facility.phone ? [facility.phone] : [],
                    address: facility.addressDetail?.unrestricted_value || "",
                    geoData: facility.coordinates ? facility.coordinates.split(",").map((v) => +v) : [],
                    workingTime: getTimetableData(facility.workingTime),
                    isAvailableForGuide: false,
                };
                delete facilityData.phone;
                delete facilityData.addressDetail;
                delete facilityData.coordinates;

                facilityCreated = !isExistingPartnerFacility
                    ? facilitySaved
                        ? { ...facilitySaved }
                        : await createFacility(facilityData)
                    : selectedPartnerFacility;
                setFacilitySaved(facilityCreated);
            }

            const excursionData: ExcursionDataPost = formatExcursionToPost(
                data,
                tariffs,
                isShowServicePlace && facilityCreated?.cmsFacilityId ? facilityCreated.cmsFacilityId : null
            );

            const excursionCreated: ExcursionDataCreated = excursionSaved
                ? { ...excursionSaved }
                : await createExcursion(excursionData);
            setExcursionSaved(excursionCreated);

            if (tariffs.length > 0 && !tariffsAreSaved) {
                await createTariffs(tariffs, +excursionCreated.billingProductId);
                setTariffsAreSaved(true);
            }

            if (
                isShowServicePlace &&
                !facilitiesAreApproved &&
                !isExistingPartnerFacility &&
                facilityCreated?.cmsFacilityId
            ) {
                await sendFacilityForApproval(facilityCreated?.cmsFacilityId);
                setFacilitiesAreApproved(true);
            }

            await sendExcursionForApproval(excursionCreated.cmsExcursionId);
            setIsFormSending(false);

            openEventStatusModal({
                type: CreateEventResultStatusEnum.Success,
                title: `Услуга «${data.title}» отправлена на модерацию.`,
                desc: (
                    <>
                        В течение 5 рабочих дней вы получите ответ на{" "}
                        <span className="footnote">{userFullInfo.email}</span>
                    </>
                ),
                closeBtnAction: () => history.push(routes.servicesAll),
            });

            history.push(routes.servicesAll);
        } catch (err) {
            console.error(err);

            openEventStatusModal({
                type: CreateEventResultStatusEnum.Fail,
                title: `Не удалось отправить на модерацию «${data.title}».`,
                desc: StatusRequestCardSubtitleError,
                errorBtnAction: sendExursionToModerate,
                closeBtnAction: () => history.push(routes.servicesAll),
            });
        } finally {
            setIsFormSending(false);
        }
    }, [data, showcases, tariffs, userFullInfo]);

    const sendExursionUpdate = async (sendToBilling: boolean, sendToCms: boolean) => {
        if (!data.id || isFormSending) return;

        setIsSubmittingForm(true);
        if (!checkIsValid()) return;

        setIsFormSending(true);
        //TODO при редактировании экскурсии не меняется data.facility, поэтому вх параметр sendToCms = false, хотя нужен true - разобраться при рефакторинге
        const sendToCms_ = sendToCms || facility.cmsFacilityId !== data.facility;

        try {
            let newCmsFacilityId;

            if (isShowServicePlace) {
                if (facility.id) {
                    await updateFacility({
                        address: facility.address,
                        city: facility.city || [],
                        geoData: facility.geoData || [],
                        id: facility.id,
                        isAvailableForGuide: !!facility.isAvailableForGuide,
                        partnerType: facility.partnerType,
                        phones: facility.phones,
                        region: facility.region || "",
                        title: facility.title,
                        workingTime: getTimetableData(facility.workingTime),
                    });
                } else {
                    const facilityData = {
                        ...facility,
                        phones: facility.phone ? [facility.phone] : [],
                        address: facility.addressDetail?.unrestricted_value || "",
                        geoData: facility.coordinates ? facility.coordinates.split(",").map((v) => +v) : [],
                        workingTime: getTimetableData(facility.workingTime),
                        isAvailableForGuide: false,
                    };
                    delete facilityData.phone;
                    delete facilityData.addressDetail;
                    delete facilityData.coordinates;

                    const { cmsFacilityId } = await createFacility(facilityData);
                    if (cmsFacilityId) {
                        newCmsFacilityId = cmsFacilityId;
                        await sendFacilityForApproval(cmsFacilityId);
                    }
                }
            }

            const excursionData = formatExcursionToPatch(data, tariffs, excursionViewDefaultId);

            const additionalFields: Pick<ExсursionDataPatch, "facility" | "sendToBilling" | "sendToCms" | "patchMode"> =
                {
                    facility: newCmsFacilityId || facility.cmsFacilityId || undefined,
                    sendToBilling: sendToBilling,
                    sendToCms: sendToCms_,
                    patchMode: sendToCms_,
                };

            await updateExcursion({
                ...excursionData,
                ...additionalFields,
            });

            reloadData();

            openEventStatusModal({
                type: CreateEventResultStatusEnum.Success,
                ...(sendToCms_
                    ? {
                          title: `Услуга «${data.title}» отправлена на модерацию.`,
                          desc: (
                              <>
                                  В течение 5 рабочих дней вы получите ответ на{" "}
                                  <span className="footnote">{userFullInfo.email}</span>
                              </>
                          ),
                      }
                    : { title: `Услуга «${data.title}» сохранена.`, desc: "Изменения опубликованы на сайте" }),
                closeBtnAction: () => history.push(routes.servicesAll),
            });

            setEditMode(false);
        } catch (err) {
            console.error(err);

            openEventStatusModal({
                type: CreateEventResultStatusEnum.Fail,
                ...(sendToCms_
                    ? {
                          title: `Не удалось отправить на модерацию «${data.title}».`,
                          desc: StatusRequestCardSubtitleError,
                      }
                    : { desc: StatusRequestCardSubtitleError, title: `Не удалось сохранить услугу ${data.title}` }),
                errorBtnAction: () => sendExursionUpdate(sendToBilling, sendToCms_),
                closeBtnAction: () => history.push(routes.servicesAll),
            });
        } finally {
            setIsFormSending(false);
        }
    };

    //функция только для апдейта базовой цены/онлайн продажи
    const updateExcursionFields = async (updateFields: { [x in keyof ExсursionDataPatch]?: any }) => {
        setIsFormSending(true);

        const excursionData = formatExcursionToPatch(compareData, tariffs, excursionViewDefaultId);

        const additionalFields: Pick<ExсursionDataPatch, "facility" | "sendToBilling" | "sendToCms" | "patchMode"> = {
            facility: facility.cmsFacilityId,
            //это стандартные поля
            sendToBilling: false,
            sendToCms: true,
            patchMode: false,
            ...updateFields,
        };

        try {
            await updateExcursion({
                ...excursionData,
                ...additionalFields,
            });

            if (updateFields.hasOwnProperty("isCanBuy")) {
                callNotification({
                    status: StatusRequestEnum.Success,
                    body: data.isCanBuy
                        ? "Продажа на RUSSPASS отключена. Пользователи сайта смогут только ознакомиться с информацией"
                        : "Продажа на RUSSPASS включена",
                });
            }

            onChangeData({ ...data, ...updateFields });
        } catch (err) {
            console.error(err);

            callNotification({
                status: StatusRequestEnum.Error,
                body: POST_DATA_ERROR,
            });
        } finally {
            setIsFormSending(false);
        }
    };

    const addTariffForExistingExcursion = useCallback(
        async (newTariffs) => {
            setIsSubmittingForm(true);
            if (userFullInfo && userFullInfo.email && newTariffs.length > 0 && data.billingProductId) {
                try {
                    setIsFormSending(true);
                    const createdTariffs = await createTariffs(newTariffs, +data.billingProductId);
                    onChangeTariff([...tariffs, ...createdTariffs]);
                    eventDispatcher.setNotification({
                        status: StatusRequestEnum.Success,
                        body: "Новый тариф отправлен",
                    });
                } catch (error) {
                    console.error(error);
                    eventDispatcher.setNotification({
                        status: StatusRequestEnum.Error,
                        body: POST_DATA_ERROR,
                    });
                } finally {
                    setIsFormSending(false);
                }
            }
        },
        [userFullInfo, data]
    );

    const deleteTariffForExistingExcursion = useCallback(
        async (tariff) => {
            if (userFullInfo && userFullInfo.email) {
                setIsFormSending(true);
                try {
                    await updateTariff(processingForUpdateTariff({ tariff, isDelete: true }), tariff.id);
                    eventDispatcher.setNotification({
                        status: StatusRequestEnum.Success,
                        body: "Тариф удален",
                    });
                } catch (error) {
                    console.error(error);
                    eventDispatcher.setNotification({
                        status: StatusRequestEnum.Error,
                        body: POST_DATA_ERROR,
                    });
                } finally {
                    setIsFormSending(false);
                }
            }
        },
        [userFullInfo, data]
    );

    const updateTariffForExistingExcursion = useCallback(
        async (newTariff) => {
            setIsSubmittingForm(true);
            if (userFullInfo && userFullInfo.email) {
                setIsFormSending(true);
                try {
                    await updateTariff(processingForUpdateTariff({ tariff: newTariff, isDelete: false }), newTariff.id);
                    eventDispatcher.setNotification({
                        status: StatusRequestEnum.Success,
                        body: "Обновленный тариф отправлен",
                    });
                } catch (error) {
                    console.error(error);
                    eventDispatcher.setNotification({
                        status: StatusRequestEnum.Error,
                        body: POST_DATA_ERROR,
                    });
                } finally {
                    setIsFormSending(false);
                }
            }
        },
        [userFullInfo, data]
    );

    return {
        sendExursionToModerate,
        sendExursionUpdate,
        isFormSending,
        isSubmittingForm,
        isValidFacilityWorkingTime,
        navbar,
        addTariffForExistingExcursion,
        deleteTariffForExistingExcursion,
        updateTariffForExistingExcursion,
        updateExcursionFields,
    };
};
