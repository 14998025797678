import { EMAIL_REQUIRED_ERROR } from "../../../../../constants/errors";
import { yup } from "../../../../../yup";
import { APARTMENTS_CONTACT_FORM_TOPIC } from "../../../../../constants/support";

export const defaultFormValidationSchema = () =>
    yup.object().shape({
        topic: yup.string().required(),
        details: yup.string().required(),
        name: yup.string().required(),
        email: yup.string().email().required(EMAIL_REQUIRED_ERROR),
        files: yup.array().limitFilesSizeSum(),
        agreeTrueData: yup.boolean().oneOf([true], "Согласие обязательно"),
        bookingId: yup
            .string()
            .nullable()
            .when("topic", {
                is: APARTMENTS_CONTACT_FORM_TOPIC.rentHousesBookingProblem,
                then: (schema) => schema.required(),
            }),
        reason: yup
            .string()
            .nullable()
            .when("bookingId", {
                is: (bookingId: string | undefined) => !!bookingId,
                then: (schema) => schema.required(),
            }),
    });
