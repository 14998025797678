import React from "react";
import "./styles.sass";
import { Facility, FacilityType, PartnerFacilityType } from "../../types";
import { Banner, InputField, AutoSubmit, Divider } from "@russpass/partner-front-ui";
import { servicePlaceValidation } from "./validation-schema";
import { Formik, Form, FormikProps } from "formik";
import ServiceType from "../service-type/service-type";
import { maskedInputCoordinates, maskedInputPhone } from "../../../../utils/validation";
import Region from "../../../../components/async-selects/region";
import SelectAddress from "../../../../components/async-selects/address";
import SelectCity from "../../../../components/async-selects/select-city";
import PlaceTimetable from "../../../../components/timetable/place-timetable/place-timetable";
import { noop } from "lodash";
import PartnerFacilityChooser from "./PartnerFacilityChooser";
import { TSelectValue } from "../../../../types/dictionaries";

type Props = {
    facility: Facility;
    optionsPlaceTypes: FacilityType[];
    onChangeFacility: (values: Partial<Facility>) => void;
    isShowErrors: boolean;
    isDisabledFacilityForm?: boolean;
    isDisabledChooserFacility?: boolean;
    region: any;
    onChangeRegion: (region: any) => void;
    city: TSelectValue;
    onChangeCity: (city: TSelectValue) => void;
    optionsFacilityPlaces: PartnerFacilityType[];
    selectedPartnerFacility: PartnerFacilityType | null;
    changePartnerFacility: (place: PartnerFacilityType) => void;
};

const ServicePlace = ({
    facility,
    onChangeFacility,
    isShowErrors,
    optionsPlaceTypes,
    isDisabledChooserFacility,
    isDisabledFacilityForm,
    region,
    onChangeRegion,
    city,
    onChangeCity,
    optionsFacilityPlaces,
    selectedPartnerFacility,
    changePartnerFacility,
}: Props) => {
    const prepareAddress = (values: any) => {
        return {
            country: values.data.country || "",
            postal_code: values.data.postal_code || "",
            region_with_type: (values.data.region_type || "") + " " + (values.data.region || ""),
            // area_with_type: (values.data.area_type || "") + " " + (values.data.area || ""),
            // settlement_with_type: (values.data.area_type || "") + " " + (values.data.area || ""),
            city_with_type: (values.data.city_type || "") + " " + (values.data.city || ""),
            street_with_type: (values.data.street_type || "") + " " + (values.data.street || ""),
            house: values.data.house || "",
            room: values.data.room || "",
            unrestricted_value: values.unrestricted_value || "",
        };
    };

    const isShowForm = !!selectedPartnerFacility || !optionsFacilityPlaces.length;

    const isShowChooser = !!optionsFacilityPlaces.length;

    return (
        <div className="content-wrapper">
            {!isDisabledChooserFacility && (
                <div className="service-data__notification">
                    <Banner body="Если у вашей услуги несколько мест проведения, то каждую услугу необходимо заводить отдельно" />
                </div>
            )}
            <Formik
                initialValues={facility}
                validationSchema={servicePlaceValidation}
                enableReinitialize
                validateOnMount
                onSubmit={noop}
            >
                {({ values, setFieldValue }: FormikProps<Facility>) => (
                    <Form>
                        <AutoSubmit onChange={onChangeFacility} />
                        <div className="content-wrapper__form">
                            {isShowChooser && (
                                <PartnerFacilityChooser
                                    options={optionsFacilityPlaces}
                                    selectedPartnerFacility={selectedPartnerFacility}
                                    handleChange={changePartnerFacility}
                                    onChangeFacility={onChangeFacility}
                                    disabled={isDisabledChooserFacility}
                                    currentFacility={facility}
                                />
                            )}

                            {isShowChooser && isShowForm && <Divider />}

                            {isShowForm && (
                                <>
                                    <InputField
                                        label="Официальное наименование площадки"
                                        name="title"
                                        noHover
                                        disabled={isDisabledFacilityForm}
                                        placeholder="Галерея альфа"
                                        errorSettings={{
                                            showOnChange: isShowErrors,
                                        }}
                                    />
                                    <ServiceType
                                        value={
                                            optionsPlaceTypes.find((v) => v.serviceId === values.partnerType)?.name ||
                                            ""
                                        }
                                        options={optionsPlaceTypes}
                                        onChange={(value) => {
                                            setFieldValue("partnerType", value.serviceId);
                                        }}
                                        disabled={isDisabledFacilityForm}
                                        placeholder="Выберите"
                                        label="Тип"
                                        searchPlaceholder="Тип места проведения"
                                        name="partnerType"
                                        errorSettings={{
                                            showOnChange: isShowErrors,
                                        }}
                                    />
                                    <Region
                                        name="region"
                                        value={region}
                                        disabled={isDisabledFacilityForm}
                                        placeholder="Не выбрано"
                                        handleChange={(value) => {
                                            onChangeRegion(value);
                                            setFieldValue("region", value.id);
                                        }}
                                        isShowErrors={isShowErrors}
                                    />
                                    <SelectCity
                                        name="city"
                                        value={city}
                                        disabled={isDisabledFacilityForm}
                                        placeholder="Краснодар"
                                        handleChange={(value) => {
                                            onChangeCity(value);
                                            setFieldValue("city", [value.id]);
                                        }}
                                        isShowErrors={isShowErrors}
                                    />
                                    <SelectAddress
                                        name="addressDetail.unrestricted_value"
                                        value={values.addressDetail}
                                        disabled={isDisabledFacilityForm}
                                        handleChange={(value: any) => {
                                            const preparedValue: any = prepareAddress(value);
                                            Object.keys(preparedValue).forEach(function (key, index) {
                                                setFieldValue(`addressDetail.${key}`, preparedValue[key]);
                                                return key;
                                            });
                                            setFieldValue(
                                                "coordinates",
                                                value.data.geo_lat + ", " + value.data.geo_lon
                                            );
                                        }}
                                        errorSettings={{
                                            showOnChange: isShowErrors,
                                        }}
                                    />
                                    <InputField
                                        label="Географические координаты"
                                        name="coordinates"
                                        noHover
                                        disabled={isDisabledFacilityForm}
                                        placeholder="55.756847°, 37.611122°"
                                        errorSettings={{
                                            showOnChange: isShowErrors,
                                        }}
                                        renderComponent={maskedInputCoordinates}
                                    />

                                    <InputField
                                        label="Телефон"
                                        noHover
                                        placeholder="+7 (000) 000-00-00"
                                        name="phone"
                                        disabled={isDisabledFacilityForm}
                                        errorSettings={{
                                            showOnChange: isShowErrors,
                                        }}
                                        renderComponent={maskedInputPhone}
                                    />

                                    <PlaceTimetable
                                        data={values.workingTime}
                                        disabled={isDisabledFacilityForm}
                                        handleChange={(timetableValues) => {
                                            setFieldValue("workingTime", timetableValues);
                                        }}
                                        errorSettings={{ showOnChange: isShowErrors }}
                                        isWorkingMode
                                        isShowBreakTime
                                        label={"Режим работы"}
                                        btnAddItemTitle={"Добавить рабочие дни"}
                                    />
                                </>
                            )}
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    );
};

export default ServicePlace;
