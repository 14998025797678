import { ReactComponent as AlertIcon } from "../../../../../../assets/images/icons/ic_alert_circle.svg";
import styles from "./styles.module.sass";

export const RatingsContestation = () => {
    return (
        <div className={styles.wrapper}>
            <AlertIcon width="24px" height="24px" />
            <p className={styles.text}>Отзыв на оспаривании</p>
        </div>
    );
};
