import {
    emptyCategoryTextActivity,
    emptyListDescriptionActivity,
    emptyListDescriptionGuide,
    emptyListSubtitleGuide,
    navbarServices,
    navbarServicesNames,
    textOnClickAddActivity,
    textOnClickAddExcursion,
} from "./constants";
import history from "../../../history";
import routes from "../../../constants/routes";

import FilterTypeServices from "./components/filter-type-services";
import useAll from "./useAll";
import React, { useMemo, useCallback } from "react";
import { getNavbarTypeServices } from "./helper";
import { EmptyList, CommonLayout, ObjectList, Card } from "@russpass/partner-front-ui";
import { sendEventByObjectItemClick } from "../../../product_analytics/analytics";
import { ReactComponent as PlusIcon } from "../../../assets/images/icons/ic_plus_circle.svg";
import { useRentHousesOwnerProfile } from "../../../api/hooks/rentHouses";

const ServicesAll = () => {
    const {
        isLoadingContent,
        activeCategory,
        handlerChangeFilter,
        allServicesList,
        draftServicesList,
        moderationServicesList,
        publishedServicesList,
        refusedServicesList,
        archiveServicesList,
        activeTabKey,
        setActiveTabKey,
        isGuide,
        setIsLoadingContent,
    } = useAll();
    const { data: guideData } = useRentHousesOwnerProfile({}, !isGuide);

    const serviceLists = useMemo(
        () => [
            { dataName: navbarServicesNames.all, services: allServicesList },
            { dataName: navbarServicesNames.draft, services: draftServicesList },
            { dataName: navbarServicesNames.moderation, services: moderationServicesList },
            { dataName: navbarServicesNames.published, services: publishedServicesList },
            { dataName: navbarServicesNames.refused, services: refusedServicesList },
            { dataName: navbarServicesNames.archive, services: archiveServicesList },
        ],
        [
            allServicesList,
            archiveServicesList,
            draftServicesList,
            moderationServicesList,
            publishedServicesList,
            refusedServicesList,
        ]
    );

    const showExcursionsForGuide = useMemo(() => {
        if (isGuide && !allServicesList.length) {
            return !!guideData?.data?.image && !!guideData?.data?.name && !!guideData?.data?.description;
        }
        return true;
    }, [guideData, isGuide, allServicesList]);

    const filterComponent = useMemo(
        () =>
            !isGuide ? (
                <FilterTypeServices
                    filterOptions={getNavbarTypeServices()}
                    onClickFilter={(value: any) => {
                        setIsLoadingContent(true);
                        handlerChangeFilter(value);
                    }}
                    defaultFilterValue={activeCategory}
                />
            ) : undefined,
        [activeCategory, handlerChangeFilter, isGuide, setIsLoadingContent]
    );

    const addServiceTitle = useMemo(() => (!isGuide ? textOnClickAddActivity : textOnClickAddExcursion), [isGuide]);

    const onClickAddEvent = useCallback(() => history.push(routes.servicesAdd), []);

    const onClickAddExcursion = useCallback(() => history.push(routes.excursionsNew), []);

    const onAddService = useMemo(
        () => (!isGuide ? onClickAddEvent : onClickAddExcursion),
        [isGuide, onClickAddEvent, onClickAddExcursion]
    );

    const topRightButton = useMemo(() => {
        const isAllServicesTabSelected = activeTabKey === navbarServices[0].name;
        if (isAllServicesTabSelected || !activeTabKey) {
            return undefined;
        } else {
            return {
                title: addServiceTitle,
                onClick: onAddService,
                isAddButton: true,
            };
        }
    }, [activeTabKey, addServiceTitle, onAddService]);

    const isShowAddCard = useCallback(
        (dataName: string) => {
            const isAllServicesTabSelected = activeTabKey === navbarServices[0].name;
            return dataName === navbarServicesNames.all && (isAllServicesTabSelected || !activeTabKey);
        },
        [activeTabKey]
    );

    return (
        <CommonLayout
            pageTitle="Услуги"
            topRightButton={topRightButton}
            isLoadingWithoutContent={isLoadingContent}
            navbar={showExcursionsForGuide ? navbarServices : undefined}
            filterComponent={filterComponent}
            activeTabName={activeTabKey}
            handlerChangeActiveTab={setActiveTabKey}
        >
            {allServicesList.length ? (
                serviceLists.map(({ dataName, services }) => (
                    <div data-name={dataName} key={dataName}>
                        <ObjectList
                            list={services}
                            history={history}
                            emptyTextTitle={emptyCategoryTextActivity}
                            handleBeforeSelect={sendEventByObjectItemClick}
                        >
                            {isShowAddCard(dataName) && (
                                <Card
                                    title={
                                        <div style={{ display: "flex", justifyContent: "center" }}>
                                            <PlusIcon className="button__icon--left" />
                                            <div className="ml-8">{addServiceTitle}</div>
                                        </div>
                                    }
                                    onClick={onAddService}
                                    styles={{
                                        backgroundColor: "#FFFFFF",
                                        boxShadow: "0 12px 16px rgba(0, 0, 0, 0.04)",
                                        border: "none",
                                    }}
                                    qaName="add-service-card"
                                />
                            )}
                        </ObjectList>
                    </div>
                ))
            ) : (
                <EmptyList
                    description={showExcursionsForGuide ? emptyListDescriptionActivity : emptyListDescriptionGuide}
                    buttonTitle={showExcursionsForGuide ? addServiceTitle : ""}
                    onClick={onAddService}
                    buttonClassName="button--primary"
                    hideImage={!showExcursionsForGuide}
                    subtitle={showExcursionsForGuide ? "" : emptyListSubtitleGuide}
                    isBigContainer={!showExcursionsForGuide}
                />
            )}
        </CommonLayout>
    );
};

export default ServicesAll;
