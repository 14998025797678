import { AddApartmentImage, AddApartmentRate, AddApartmentRoom, AddApartmentValues } from "./types";
import {
    RentHousesDictionaryItem,
    RentHousesObjectBed,
    RentHousesObjectInfo,
    RentHousesRate,
    RentHousesScheduleType,
} from "../../../types/rentHouses";
import { NavbarApartmentsNewName } from "./constants";
import uuid from "react-native-uuid";
import { saveRentHousesImage } from "../../../api/rentHouses";
import eventDispatcher from "../../../lib/event-dispatcher";
import { StatusRequestEnum } from "@russpass/partner-front-ui";
import getCustomErrorMessage from "../../../utils/getCustomErrorMessage";
import { COMMON_RELOAD_ERROR } from "../../../constants/errors";
import history from "../../../history";
import routes from "../../../constants/routes";
import { PlaceTimetableButtonKeys } from "../../../components/timetable/place-timetable/constants";

export const mapRentHousesRate = (rate: AddApartmentRate): RentHousesRate => {
    const price = rate.price ? +rate.price.replaceAll(" ", "") : 0;

    return {
        [PlaceTimetableButtonKeys.WeekDays]: () => ({
            price,
            scheduleType: RentHousesScheduleType.DAYS_OF_WEEK,
            daysOfWeek: rate.dates?.map((el) => el.toUpperCase()),
        }),
        [PlaceTimetableButtonKeys.Days]: () => ({
            price,
            scheduleType: RentHousesScheduleType.DATES,
            dates: rate.dates?.map((el) => ({ from: el, to: el })),
        }),
        [PlaceTimetableButtonKeys.RangeDays]: () => ({
            price,
            scheduleType: RentHousesScheduleType.DATE_RANGES,
            dates: rate.dates,
        }),
    }[rate.scheduleType]();
};

export const mapValuesToObjectInfo = (values: AddApartmentValues): RentHousesObjectInfo => {
    const valImages = values.photo?.photos?.filter((el) => el.id);

    const images = valImages?.map((el, idx) => ({
        id: el.id || "",
        caption: el.extra,
        isMain: idx === 0,
    }));

    const rooms = values.details?.rooms?.[0].roomType
        ? values.details?.rooms?.map((el) => {
              const beds: RentHousesObjectBed[] = [];
              for (const entry of Object.entries(el.beds)) {
                  const [id, count] = entry;
                  beds.push({ id, count });
              }

              return { roomType: el.roomType, beds };
          })
        : undefined;

    return {
        title: values.object?.name || undefined,
        description: values.object?.description || undefined,
        housingType: values.object?.type || undefined,
        area: values.object?.area ? +values.object?.area : undefined,
        amenities: values.object?.amenities?.length ? values.object.amenities : undefined,
        region: values.object?.region || undefined,
        timeZone: values.object?.timeZone || undefined,
        timeZoneValue: values.object?.timeZoneValue || undefined,
        city: values.object?.city || undefined,
        address: values.object?.address?.unrestricted_value || undefined,
        coordinates:
            values.object?.address?.data?.geo_lat && values.object?.address?.data?.geo_lon
                ? [+values.object.address.data.geo_lat, +values.object.address.data.geo_lon]
                : undefined,
        maxGuestCount: values.details?.maxGuestCount ? +values.details?.maxGuestCount : undefined,
        bathroomCount: values.details?.bathroomCount ? +values.details?.bathroomCount : undefined,
        checkinTime: values.details?.arrival?.start || undefined,
        checkoutTime: values.details?.arrival?.end || undefined,
        checkinType: values.details?.checkinType || undefined,
        homeRules: values.details?.homeRules?.length ? values.details.homeRules : undefined,
        rooms,
        images: images?.length ? images : undefined,
        rates: (values.rates?.rates?.[0]?.dates && values.rates?.rates?.map(mapRentHousesRate)) || undefined,
        cancellationPolicy: values.discountsCancellations?.cancellationPolicy || undefined,
        discounts: values.discountsCancellations?.discount
            ? [
                  {
                      type: "DAYSCOUNT",
                      unit: "PERCENT",
                      amount: values.discountsCancellations.discount,
                      daysCount: values.discountsCancellations?.rentFrom || 0,
                  },
              ]
            : undefined,
    };
};

export const mapObjectInfoToValues = (
    info: RentHousesObjectInfo,
    dictionary?: { timeZone: RentHousesDictionaryItem[] }
): AddApartmentValues => {
    const rooms: AddApartmentRoom[] = [];

    let timeZoneValue: string = info.timeZoneValue || "";
    if (info.timeZone && dictionary?.timeZone?.length) {
        const timeZoneItem = dictionary.timeZone.find((el) => el.id === info.timeZone);
        if (timeZoneItem?.dictionary_data?.utc) {
            timeZoneValue = timeZoneItem?.dictionary_data?.utc;
        }
    }

    info.rooms?.forEach((room) => {
        let beds: Record<string, number> = {};
        room.beds.forEach((bed) => {
            beds[bed.id] = bed.count;
        });
        rooms.push({ beds, roomType: room.roomType });
    });

    const rates: AddApartmentRate[] = info.rates?.map((rate) => {
        const price = rate.price ? `${rate.price}` : "";

        return {
            [RentHousesScheduleType.DAYS_OF_WEEK]: () => ({
                price,
                scheduleType: PlaceTimetableButtonKeys.WeekDays,
                dates: rate.daysOfWeek?.map((el) => el.toLowerCase()) || [],
                id: uuid.v4(),
            }),
            [RentHousesScheduleType.DATES]: () => ({
                price,
                scheduleType: PlaceTimetableButtonKeys.Days,
                dates: rate.dates?.map((el) => el.from) || [],
                id: uuid.v4(),
            }),
            [RentHousesScheduleType.DATE_RANGES]: () => ({
                price,
                scheduleType: PlaceTimetableButtonKeys.RangeDays,
                dates: rate.dates || [],
                id: uuid.v4(),
            }),
        }[rate.scheduleType]();
    }) || [{} as AddApartmentRate];

    return {
        [NavbarApartmentsNewName.OBJECT]: {
            name: info.title || "",
            description: info.description || "",
            area: info.area != null ? `${info.area}` : "",
            amenities: info.amenities || [],
            region: info.region || "",
            city: info.city || "",
            timeZone: info.timeZone || "",
            timeZoneValue,
            address: {
                unrestricted_value: info.address || "",
                data:
                    info.coordinates?.[0] != null && info.coordinates?.[1] != null
                        ? {
                              geo_lat: info.coordinates[0],
                              geo_lon: info.coordinates[1],
                          }
                        : undefined,
            },
            type: info.housingType || "",
        },
        [NavbarApartmentsNewName.DETAILS]: {
            maxGuestCount: info.maxGuestCount != null ? `${info.maxGuestCount}` : "",
            bathroomCount: info.bathroomCount != null ? `${info.bathroomCount}` : "",
            arrival: {
                start: info.checkinTime ? `${info.checkinTime}` : "",
                end: info.checkoutTime ? `${info.checkoutTime}` : "",
            },
            checkinType: info.checkinType || "",
            homeRules: info.homeRules || [],
            rooms: rooms.length ? rooms : undefined,
        },
        [NavbarApartmentsNewName.PHOTO]: {
            photos: info.images?.map((el) => ({ id: el.id, extra: el.caption })) || [],
        },
        [NavbarApartmentsNewName.TARIFFS]: {
            rates: rates.length ? rates : undefined,
        },
        [NavbarApartmentsNewName.SALE_AND_CANCEL]: {
            cancellationPolicy: info.cancellationPolicy || "",
            discount: info.discounts?.[0]?.amount,
            rentFrom: info.discounts?.[0]?.daysCount,
            hasDiscount: info.discounts?.[0]?.amount != null,
        },
    };
};

export const savePhotos = async (values: AddApartmentValues): Promise<AddApartmentImage[] | false> => {
    try {
        let photos: AddApartmentImage[] = [];

        for (const photo of values.photo?.photos || []) {
            if (photo.id) {
                photos.push({
                    ...photo,
                });
                continue;
            }
            if (photo.file) {
                const {
                    data: { cmsId },
                } = await saveRentHousesImage(photo.file);
                photos.push({
                    ...photo,
                    id: cmsId,
                });
            }
        }
        return photos;
    } catch (e) {
        eventDispatcher.setNotification({
            status: StatusRequestEnum.Error,
            body: getCustomErrorMessage(e, COMMON_RELOAD_ERROR),
        });
        return false;
    }
};

export const checkDirtyValues = (initialValues: AddApartmentValues, currentValues: AddApartmentValues) => {
    const isDirtyObject = !(
        initialValues.object?.name === currentValues.object?.name &&
        initialValues.object?.description === currentValues.object?.description &&
        initialValues.object?.area === currentValues.object?.area &&
        initialValues.object?.amenities === currentValues.object?.amenities &&
        initialValues.object?.region === currentValues.object?.region &&
        initialValues.object?.city === currentValues.object?.city &&
        initialValues.object?.timeZone === currentValues.object?.timeZone &&
        initialValues.object?.type === currentValues.object?.type &&
        initialValues.object?.address?.unrestricted_value === currentValues.object?.address?.unrestricted_value
    );

    const isDirtyDetails = !(
        initialValues.details?.maxGuestCount === currentValues.details?.maxGuestCount &&
        initialValues.details?.bathroomCount === currentValues.details?.bathroomCount &&
        initialValues.details?.arrival === currentValues.details?.arrival &&
        initialValues.details?.checkinType === currentValues.details?.checkinType &&
        initialValues.details?.homeRules === currentValues.details?.homeRules &&
        initialValues.details?.rooms === currentValues.details?.rooms
    );

    const isDirtyPhoto = initialValues.photo?.photos !== currentValues.photo?.photos;

    const isDirtyTariffs = initialValues.rates?.rates !== currentValues.rates?.rates;

    const isDirtySale = !(
        initialValues.discountsCancellations?.discount === currentValues.discountsCancellations?.discount &&
        initialValues.discountsCancellations?.cancellationPolicy ===
            currentValues.discountsCancellations?.cancellationPolicy &&
        initialValues.discountsCancellations?.rentFrom === currentValues.discountsCancellations?.rentFrom &&
        initialValues.discountsCancellations?.hasDiscount === currentValues.discountsCancellations?.hasDiscount
    );

    return isDirtyObject || isDirtyDetails || isDirtyPhoto || isDirtyTariffs || isDirtySale;
};

export const redirectToApartmentsAll = (location: any) => {
    history.push({
        pathname: routes.apartmentsAll,
        state: location?.state,
    });
};
