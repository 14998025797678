import React, { useCallback, useMemo } from "react";
import { ExcursionDataClient, ExcursionEventItem } from "../helpers/types";
import { NavbarExcursionNamesEnum } from "../helpers/constants";
import { Form, Formik } from "formik";
import { excursionProgramValidation } from "../helpers/validation-schema";
import {
    AutoSubmit,
    DndUploadPhotos,
    InputListField,
    UploadPhotosItem,
    useViewportContext,
} from "@russpass/partner-front-ui";
import { getImageById, uploadPhotoToCms } from "../../../../api/files";
import { onError, onFileTooLarge, onPhotosLimit } from "../../../../utils/images";
import styles from "./excursion.module.sass";
import classNames from "classnames";

type Props = {
    data: ExcursionDataClient;
    onChange: (values: ExcursionDataClient) => void;
    onSubmit: (path: string) => void;
    isShowErrors: boolean;
    disabled: boolean;
};

const ExcursionProgram = ({ data, onChange, onSubmit, isShowErrors, disabled }: Props) => {
    const { isLaptop } = useViewportContext();

    const images = useMemo(() => {
        const _images: UploadPhotosItem[] = [];
        data.route[0]?.events.forEach((event, indx) => {
            if (event.imageFiles) {
                _images.push({
                    image: event.imageFiles[0].id,
                    description: {
                        name: `route[${0}].events[${indx}].title`,
                        placeholder: "Добавить описание",
                        noHover: true,
                        maxRows: 2,
                        errorSettings: { showOnChange: isShowErrors },
                        disabled,
                        className: styles.photoDescText,
                    },
                });
            }
        });
        return _images;
    }, [data, disabled, isShowErrors]);

    const cardsInRowMaxCount = useMemo(() => {
        const minPhotosCount = 2;
        const maxPhotosCount = isLaptop ? 3 : 4;
        if (images.length < minPhotosCount) return minPhotosCount;
        else return images.length < maxPhotosCount ? images.length : maxPhotosCount;
    }, [images.length, isLaptop]);

    const handleChange = useCallback(
        (values: ExcursionEventItem[]) =>
            onChange({
                ...data,
                route: [{ events: [...values], title: "" }],
            }),
        [onChange, data]
    );

    const uploadPhotos = (values: UploadPhotosItem[]) => {
        const mapped = new Map(data.route[0]?.events.map((i) => [i.imageFiles?.[0].id, i.title]));

        const updatedData = values.map((item, indx) => {
            return {
                title: mapped.get(item.image) || "",
                imageFiles: [{ id: item.image }],
            };
        });

        handleChange(updatedData);
    };

    return (
        <Formik
            initialValues={data}
            enableReinitialize
            validateOnMount
            validationSchema={excursionProgramValidation}
            onSubmit={() => {
                onSubmit(NavbarExcursionNamesEnum.Photos);
            }}
        >
            <Form>
                <div className={classNames("content-wrapper", styles.wrapper)}>
                    <div className="content-wrapper__form">
                        <AutoSubmit onChange={onChange} />
                        <InputListField
                            label="Программа"
                            name="program"
                            placeholderItems={["07:45 Сбор группы", "08:00 Отправление автобуса"]}
                            errorSettings={{ showOnChange: isShowErrors }}
                            uniquePrefixId="program"
                            disabled={disabled}
                        />
                        <InputListField
                            label="Входит в стоимость"
                            name="included"
                            placeholderItems={["Поездка на автобусе", "Услуги гида"]}
                            errorSettings={{ showOnChange: isShowErrors }}
                            uniquePrefixId="included"
                            disabled={disabled}
                        />
                        <InputListField
                            label="Оплачивается отдельно"
                            name="paidSeparately"
                            placeholderItems={["Обед и ужин", "Посещение музея"]}
                            errorSettings={{ showOnChange: isShowErrors }}
                            uniquePrefixId="paidSeparately"
                            disabled={disabled}
                        />
                    </div>
                </div>
                <div className={styles.containerPhotos}>
                    <DndUploadPhotos
                        items={images}
                        onChange={uploadPhotos}
                        disabled={disabled}
                        title="Добавить фото объектов экскурсии"
                        cardHeight={316}
                        cardMinHeight={316}
                        cardsGap={{ columnGap: 16, rowGap: 48 }}
                        getImageById={getImageById}
                        uploadPhoto={uploadPhotoToCms}
                        onFileTooLarge={onFileTooLarge}
                        onPhotosLimit={onPhotosLimit}
                        onError={onError}
                        viewTag={""}
                        columnCount={cardsInRowMaxCount}
                        isAvailablePhotoGuide={false}
                        isMaxWidthCardsUnset
                        cardMinWidth={213}
                    />
                </div>
            </Form>
        </Formik>
    );
};

export default ExcursionProgram;
