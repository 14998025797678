import { FeatureToggleListType } from "@russpass/feature-toggle";
import { featureToggleNames } from "../constants/feature-toggles";

export const featureTogglesList: FeatureToggleListType = [
    {
        groupName: "Услуги",
        toggles: [
            {
                label: "Туры",
                OFFHint: "в выключенном состоянии туры не отображаются в ЛКП",
                ONHint: "в включенном состоянии туры доступны для просмотра/добавления",
                envName: featureToggleNames.IS_AVAILABLE_TOURS,
            },
        ],
    },
    {
        groupName: "Отели",
        toggles: [
            {
                label: "Аналитика",
                OFFHint: "в выключенном состоянии аналитика для отелей не отображается",
                ONHint: "в включенном состоянии аналитика для отелей отображается",
                envName: featureToggleNames.IS_VISIBLE_ANALYTICS,
            },
            {
                label: "Перевод TravelLine",
                OFFHint: "в выключенном состоянии ТЛ работает по старой схеме",
                ONHint: "в включенном состоянии ТЛ переводится на новый ТЛ",
                envName: featureToggleNames.IS_ENABLE_MOVING_OLD_TL,
            },
            {
                label: "Рейтинг v2",
                OFFHint: "в выключенном состоянии в ЛКП рейтинг v1",
                ONHint: "в включенном состоянии в ЛКП рейтинг v2",
                envName: featureToggleNames.RATING_V2,
            },
            {
                label: "Уведомления",
                OFFHint: "в выключенном состоянии нет функционала уведомлений для отелей",
                ONHint: "в включенном состоянии Уведомления для отелей доступны",
                envName: featureToggleNames.IS_ENABLE_HOTEL_NOTIFICATIONS,
            },
        ],
    },
    {
        groupName: "Жилье",
        toggles: [
            {
                label: "Страхование - иконка-тултип в списке бронирований",
                OFFHint: "в выключенном состоянии нет иконки о страховании",
                ONHint: "в включенном состоянии есть иконка о страховании",
                envName: featureToggleNames.IS_ENABLE_RENTHOUSE_INSURANCE_INFORMATION,
            },
            {
                label: "Чат поддержки [RUSSPASS-37931]",
                OFFHint: "в выключенном чат не отображается во вкладке сообщения",
                ONHint: "в включенном чат отображается во вкладке сообщения",
                envName: featureToggleNames.IS_RENTHOUSES_SUPPORT_CHAT_ENABLED,
            },
        ],
    },
    {
        groupName: "Рестораны",
        toggles: [
            {
                label: "Поля 'Тип заведения' и 'Особенности'",
                OFFHint:
                    "в выключенном состоянии поля 'Тип заведения', 'Особенности' и 'Тэги' не отображаются в форме ресторана",
                ONHint: "в включенном состоянии поля 'Тип заведения', 'Особенности' и 'Тэги' отображаются в форме ресторана",
                envName: featureToggleNames.IS_ENABLE_RESTAURANT_NEW_FIELDS,
            },
            {
                label: "Бронирование на RUSSPASS [RUSSPASS-41582]",
                OFFHint: 'в выключенном состоянии в настройках ресторана поле отсутствует "Бронирование на RUSSPASS"',
                ONHint: 'в включенном состоянии в настройках ресторана присутствует поле "Бронирование на RUSSPASS" ',
                envName: featureToggleNames.CAN_RESERVE_ENABLE,
            },
        ],
    },
];
