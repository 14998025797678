export const alertNoBasicTariffText = {
    description: `Откройте нужный тариф и поставьте галочку в соответствующем поле. Обычно к базовому относят взрослый/стандартный тариф`,
    title: "Выберите базовый тариф для цены в каталоге",
    OKButton: "Хорошо",
};

export const alertConfirmSaveText = {
    description: `Новые данные будут опубликованы на сайте`,
    title: "Сохранить изменения?",
    OKButton: "Сохранить и выйти",
    cancelButton: "Выйти без сохранения",
};

export const backButtonText = {
    description: `При выходе внесенные изменения не сохранятся`,
    title: "Чтобы сохранить их, вернитесь к редактированию и отправьте данные на модерацию",
    OKButton: "Вернуться к редактированию",
    cancelButton: "Выйти без сохранения",
};
